import React, {useState} from 'react';
import {View} from 'react-native';
import {navigate} from '../navigation';
import Button from './Button';
import HStack from './HStack';
import IconButton from './IconButton';
import Text from '../components/Text';
import ConfirmationDialog from './ConfirmationDialog';

interface I_RegisiterActionBar_Props {
  step: number;
  hasSkip?: boolean;
  skipHandler?: ()=> void;
  onSkip?: ()=> void;
}

const RegisterActionBar = ({
  step,
}: I_RegisiterActionBar_Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onClose = () => setIsOpen(false);

  const onYesPressed = () => {
    navigate('Sign In', null);
  };

  const onNoPressed = () => {
    console.log('No');
  };

  return (
    <HStack
      style={{
        marginTop: 8,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <View style={{ flex: 1}}>
        <IconButton
          icon={'close-outline'}
          size={30}
          onPress={() => setIsOpen(!isOpen)}
        />
      </View>
      <View style={{ flex: 1}}>
        <Text>{`${step} of 7 steps`}</Text>
      </View>
      <ConfirmationDialog
        isOpen={isOpen}
        onClose={onClose}
        onNoPressed={onNoPressed}
        onYesPressed={onYesPressed}
        description="Are you sure you wish to abort registration? Your data will not be saved."
      />
    </HStack>
  );
};

export default RegisterActionBar;
