import {createStackNavigator} from '@react-navigation/stack';
import React from 'react';
import {Alerts, NotificationDetails} from '../modules/Alerts/screens';
import {EditUnit, UnitCalendar, UnitChooser} from '../modules/Units/screens';
import {People} from '../modules/People/screens';
import {
  AddDelivery,
  DeliveriesPackages,
  DeliveryDetail,
  PackageDetails,
} from '../modules/Deliveries/screens';
import WebDrawerScreensWrapper from './WebDrawerScreensWrapper';
import VehiclesNavigator from '../modules/Vehicles/navigator';
import PetsNavigator from '../modules/Pets/navigator';
import StoragesNavigator from '../modules/Storage/navigator';
import ParkingsNavigator from '../modules/Parking/navigator';
import GatecardsNavigator from '../modules/Gatecards/navigator';
import KeyLogsNavigator from '../modules/Keylogs/navigator';
import VisitorLogsNavigator from '../modules/VisitorLogs/navigator';
import DoorLogsNavigator from '../modules/DoorLogs/navigator';
import PackageHistoryNavigator from '../modules/Deliveries/navigator';
import ViolationsNavigator from '../modules/Violations/navigator';
import {Directory, EditDirectoryInfo} from '../modules/Directory/screens';
import {E_NAVIGATION_DESTINATIONS} from '../@types';
import {EditProfile} from '../modules/Auth/screens';
import IDCard from '../modules/Dashboard/screen/IDCard';
import {HowTo, SettingsScreen} from '../screens/more';
import {UserPermissions} from '../modules/Permissions';
import About from '../screens/more/About';
import {
  AddWithUnitCode,
  CreatePeopleAndLink,
} from '../modules/UnitPeopleLink/screens';
import DevicePermissions from '../screens/more/DevicePermissions.web';
import { Documents } from '../modules/Documents/screens';

const StackNavigator = createStackNavigator();

const WebNavigator = props => {
  return (
    <>
      <StackNavigator.Navigator
        screenOptions={{
          headerShown: false,
        }}>
        <StackNavigator.Screen name="Alerts">
          {props => (
            <WebDrawerScreensWrapper>
              <Alerts {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen name="Units">
          {props => (
            <WebDrawerScreensWrapper>
              <UnitChooser {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen name="People">
          {props => (
            <WebDrawerScreensWrapper>
              <People {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen name="Packages">
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <DeliveriesPackages {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen name={E_NAVIGATION_DESTINATIONS.DOCUMENTS}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <Documents {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen name={E_NAVIGATION_DESTINATIONS.EDIT_PROFILE}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <EditProfile {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen name={E_NAVIGATION_DESTINATIONS.EDIT_UNIT}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <EditUnit {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen name={E_NAVIGATION_DESTINATIONS.ID_CARD}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <IDCard {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen
          name={E_NAVIGATION_DESTINATIONS.NOTIFICATION_DETAILS}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <NotificationDetails {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen name={E_NAVIGATION_DESTINATIONS.OTHERS}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <SettingsScreen {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen
          name={E_NAVIGATION_DESTINATIONS.NOTIFICATION_SETTINGS}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <DevicePermissions {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen name={E_NAVIGATION_DESTINATIONS.UNIT_CALENDAR}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <UnitCalendar {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen
          name={E_NAVIGATION_DESTINATIONS.USER_PERMISSIONS}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <UserPermissions {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen name={E_NAVIGATION_DESTINATIONS.PACKAGE_DETAILS}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <PackageDetails {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen
          name={E_NAVIGATION_DESTINATIONS.DELIVERY_DETAILS}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <DeliveryDetail {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen name={E_NAVIGATION_DESTINATIONS.ADD_DELIVERY}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <AddDelivery {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen name={E_NAVIGATION_DESTINATIONS.ABOUT}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <About {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen name={E_NAVIGATION_DESTINATIONS.HOWTO}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <HowTo {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen
          name={E_NAVIGATION_DESTINATIONS.ADD_WITH_UNITCODE}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <AddWithUnitCode {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        <StackNavigator.Screen
          name={E_NAVIGATION_DESTINATIONS.CREATE_PEOPLE_AND_LINK}>
          {props => (
            <WebDrawerScreensWrapper>
              {/* @ts-ignore */}
              <CreatePeopleAndLink {...props} />
            </WebDrawerScreensWrapper>
          )}
        </StackNavigator.Screen>
        {/* @ts-ignore */}
        <StackNavigator.Screen
          name="Vehicles"
          component={VehiclesNavigator}></StackNavigator.Screen>
        {/* @ts-ignore */}
        <StackNavigator.Screen
          name="Pets"
          component={PetsNavigator}></StackNavigator.Screen>
        {/* @ts-ignore */}
        <StackNavigator.Screen
          name="Storages"
          component={StoragesNavigator}></StackNavigator.Screen>
        {/* @ts-ignore */}
        <StackNavigator.Screen
          name="Parking"
          component={ParkingsNavigator}></StackNavigator.Screen>
        {/* @ts-ignore */}
        <StackNavigator.Screen
          name="GateCards"
          component={GatecardsNavigator}></StackNavigator.Screen>
        {/* @ts-ignore */}
        <StackNavigator.Screen
          name="KeyLogs"
          component={KeyLogsNavigator}></StackNavigator.Screen>
        {/* @ts-ignore */}
        <StackNavigator.Screen
          name="VisitorHistory"
          component={VisitorLogsNavigator}></StackNavigator.Screen>
        {/* @ts-ignore */}
        <StackNavigator.Screen
          name="DoorLogs"
          component={DoorLogsNavigator}></StackNavigator.Screen>
        {/* @ts-ignore */}
        <StackNavigator.Screen
          name="PackagesHistory"
          component={PackageHistoryNavigator}></StackNavigator.Screen>
        {/* @ts-ignore */}
        <StackNavigator.Screen
          name="Search"
          component={Directory}></StackNavigator.Screen>
        <StackNavigator.Screen
          name="Edit Directory Settings"
          component={EditDirectoryInfo}></StackNavigator.Screen>
        {/* @ts-ignore */}
        <StackNavigator.Screen
          name="Violations"
          component={ViolationsNavigator}></StackNavigator.Screen>
      </StackNavigator.Navigator>
    </>
  );
};

export default WebNavigator;
