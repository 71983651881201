// @ts-nocheck
import React, {createRef, useEffect, useState} from 'react';
import {CardStyleInterpolators} from '@react-navigation/stack';
import SignInScreen from '../modules/Auth/screens/SignInScreen';
import {ConfirmationCodesEntry, EmailCheck, ForgotPassword, TrainingVideo, Welcome} from '../modules/Auth/screens';
import {createNativeStackNavigator} from '@react-navigation/native-stack';

import { E_NAVIGATION_DESTINATIONS, RootStackParamList} from '../@types';
import buildRoutes from './routes';
import {
  AccountSetupInfo,
  DlCapture,
  Email,
  EmailConfirmation,
  Name,
  Phone,
  PhoneConfirmation,
  PhotoCapture,
  RegisterProgress,
  Success,
  UserCredentials,
} from '../modules/Auth/screens';
import {makeId} from '../utils';
import {UnitList} from '../modules/Units/screens';
import isWeb from '../utils/isWeb';
import injectFonts from '../utils/injectFonts';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducers';

 if(isWeb){
  injectFonts();
 }

export const navigationRef = createRef();

export const navigate = (name: string, params: any) => {
  //@ts-ignore
  navigationRef.current?.navigate(name, params);
};

export function goBack() {
  //@ts-ignore
  navigationRef.current?.goBack();
}

const Stack = createNativeStackNavigator<RootStackParamList>();

export default function StackNavigator() {
  const token = useSelector((state:RootState) => state.auth.token);
  const defaultUnitPeopleLink = useSelector((state: RootState) => state.units.unitLinks?.filter(link => link.isDefault === true)[0])
  const [routesWithPermissions, setRoutesWithPermission] = useState(
    buildRoutes(defaultUnitPeopleLink, hasUnitLinks),
  );

  useEffect(() => {
    setRoutesWithPermission(buildRoutes(defaultUnitPeopleLink, hasUnitLinks));
  }, []);

  const options = {
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    headerShown: false,
  };

  return (
    <Stack.Navigator>
      {token === null || token === undefined ? (
        <>
          {/* <Stack.Screen
            name="Camera"
            component={CameraScreen}
            options={{...options, animationTypeForReplace: 'pop'}}
          /> */}
          <Stack.Screen
            name="Sign In"
            component={SignInScreen}
            options={{...options, animationTypeForReplace: 'pop'}}
            key={makeId(12)}
          />
          <Stack.Screen
            name="Welcome"
            component={Welcome}
            options={{...options, animationTypeForReplace: 'pop'}}
            key={makeId(12)}
          />
          <Stack.Screen
            name="Account Setup Info"
            component={AccountSetupInfo}
            options={{...options, animationTypeForReplace: 'pop'}}
            key={makeId(12)}
          />
          <Stack.Screen
            name="Dl Capture"
            component={DlCapture}
            options={{...options, animationTypeForReplace: 'pop'}}
            key={makeId(12)}
          />
          <Stack.Screen
            name="Name"
            component={Name}
            options={{...options, animationTypeForReplace: 'pop'}}
            key={makeId(12)}
          />
          <Stack.Screen
            name="Photo Capture"
            component={PhotoCapture}
            options={{...options, animationTypeForReplace: 'pop'}}
            key={makeId(12)}
          />
          <Stack.Screen
            name="Email"
            component={Email}
            options={{...options, animationTypeForReplace: 'pop'}}
          />
          <Stack.Screen
            name={E_NAVIGATION_DESTINATIONS.CONFIRIRMATION_CODES_ENTRY}
            component={ConfirmationCodesEntry}
            options={{...options, animationTypeForReplace: 'pop'}}
            key={makeId(12)}
          />
          <Stack.Screen
            name={'Email Check'}
            component={EmailCheck}
            options={{...options, animationTypeForReplace: 'pop'}}
            key={makeId(12)}
          />

          <Stack.Screen
            name="Register Progress"
            component={RegisterProgress}
            options={{...options, animationTypeForReplace: 'pop'}}
            key={makeId(12)}
          />
          <Stack.Screen
            name="Phone Confirmation"
            component={PhoneConfirmation}
            options={{...options, animationTypeForReplace: 'pop'}}
            key={makeId(12)}
          />

          <Stack.Screen
            name="User Credentials"
            component={UserCredentials}
            options={{...options, animationTypeForReplace: 'pop'}}
            key={makeId(12)}
          />
          <Stack.Screen
            name="Success"
            component={Success}
            options={{...options, animationTypeForReplace: 'pop'}}
            key={makeId(12)}
          />
          <Stack.Screen
            name="Forgot Password"
            component={ForgotPassword}
            options={{...options, animationTypeForReplace: 'pop'}}
            key={makeId(12)}
          />
          <Stack.Screen
            name="Trainning Video"
            component={TrainingVideo}
            options={{...options, animationTypeForReplace: 'pop'}}
            key={makeId(12)}
          />
        </>
      ) : (
        <>
          {defaultUnitPeopleLink === null ||
            (defaultUnitPeopleLink === undefined && (
              <Stack.Screen
                name="Units"
                component={UnitList}
                options={{...options}}
                key={makeId(12)}
              />
            ))}
          {routesWithPermissions.map((route, index) => (
            <>
              {route.canView && (
                <Stack.Screen
                  name={route.name}
                  component={route.component}
                  options={{...route.options}}
                  key={index}
                />
              )}
            </>
          ))}
        </>
      )}
    </Stack.Navigator>
  );
}