import React from 'react';
import {View} from 'react-native';
import Text from '../../../components/Text';
import useTailwind from '../../../components/useTailwind';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import IconButton from '../../../components/IconButton';
import {Center} from 'native-base';
import ScreenContent from '../components/ScreenContent';
import isWeb from '../../../utils/isWeb';

const AccountSetupInfo = ({navigation}) => {
  const tailwind = useTailwind();
  
  return (
    <Center style={[tailwind('mb-4 flex-1')]}>
      <ScreenContent>
        <IconButton
          icon={'close-outline'}
          size={30}
          style={tailwind('')}
          onPress={() => navigation.goBack()}
        />
        <View style={tailwind('mx-6 mt-4 flex-1 ')}>
          <Heading>Account setup information </Heading>
          <Text style={tailwind('mt-6 text-lg')}>
            To setup a new account, we will require you to provide :
          </Text>
          <HStack style={tailwind('mt-6')}>
            <View
              style={tailwind('border border-gray-200 rounded-full w-10 h-10')}>
              <Text style={tailwind('text-center mt-2')}>1.</Text>
            </View>
            <Text style={tailwind('mt-2 ml-2 text-lg')}>
              A picture of your driver's license.
            </Text>
          </HStack>
          <HStack style={tailwind('mt-6')}>
            <View
              style={tailwind('border border-gray-200 rounded-full w-10 h-10')}>
              <Text style={tailwind('text-center mt-2')}>2.</Text>
            </View>
            <Text style={tailwind('mt-2 ml-2 text-lg')}>
              Your personal information eg name .
            </Text>
          </HStack>
          <HStack style={tailwind('mt-6')}>
            <View
              style={tailwind('border border-gray-200 rounded-full w-10 h-10')}>
              <Text style={tailwind('text-center mt-2')}>3.</Text>
            </View>
            <Text style={tailwind('mt-2 ml-2 text-lg')}>A photo of you.</Text>
          </HStack>
          <HStack style={tailwind('mt-6 mr-4')}>
            <View
              style={tailwind('border border-gray-200 rounded-full w-10 h-10')}>
              <Text style={tailwind('text-center mt-2')}>4.</Text>
            </View>
            <Text style={tailwind('mt-2 ml-2 text-lg')} numberOfLines={3}>
              Communication information e.g email and cell phone number .
            </Text>
          </HStack>
          <Button
            onPress={() => navigation.navigate('Trainning Video')}
            variant="link"
            size="lg"
            style={tailwind('mt-12 text-center')}>
            Install Help, Tap Here
          </Button>
          {isWeb && (
            <Button
              size="lg"
              py="4"
              mb="4"
              mt="16"
              width={'full'}
              onPress={() => navigation.navigate('Email Check')}
              style={tailwind('mx-6')}>
              Get started
            </Button>
          )}
        </View>
      </ScreenContent>
      {!isWeb && (
        <HStack mx="4">
          <Button
            size="lg"
            py="4"
            mb="4"
            width={'full'}
            onPress={() => navigation.navigate('Email Check')}
            style={tailwind('mx-6')}>
            Get started
          </Button>
        </HStack>
      )}
    </Center>
  );
};

export default AccountSetupInfo;
