import {Button, Center, Modal, VStack} from 'native-base';
import React, {useCallback, useRef, useState} from 'react';
import {ScrollView, View} from 'react-native';
import Webcam from 'react-webcam';
import useTailwind from '../../../components/useTailwind';


// @ts-nocheck
export default function WebcamCapture({onCapture}) {
  const [modalVisible, setModalVisible] = React.useState(false);
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  const handleSizeClick = () => {
    setModalVisible(!modalVisible);
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef]);

  const tailwind = useTailwind();

  return (
    <>
      <Modal isOpen={modalVisible} onClose={setModalVisible} size={'xl'}>
        <Modal.Content maxH="600">
          <Modal.CloseButton />
          <Modal.Header>Capture Photo</Modal.Header>
          <Modal.Body>
            <ScrollView
            contentContainerStyle={{
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
              {imgSrc ? (
                <img src={imgSrc} alt="webcam" />
              ) : (
                <Webcam
                  screenshotFormat="image/jpeg"
                  audio={false}
                  height={600}
                  width={600}
                  ref={webcamRef}
                />
              )}
            </ScrollView>
          </Modal.Body>
          <Modal.Footer>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              {!!imgSrc ? (
                 <Button
                 variant="outline"
                 colorScheme="blueGray"
                 onPress={() => {
                   setImgSrc(null);
                 }}>
                 Retake
               </Button>
              ) : (
                <Button onPress={capture}>Take</Button>
              )}
              {!!imgSrc && (
                <Button
                  onPress={() => {
                    /**
                     * Close modal and forward the image to parent....
                     */
                    onCapture(imgSrc.split(',')[1])
                    setModalVisible(false)
                    setImgSrc(null);
                  }}>
                  Save
                </Button>
              )}
            </View>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Center>
        <VStack space={4}>
          <Button
            style={tailwind('flex-1 mt-1 mb-4')}
            variant="ghost"
            size="lg"
            py="4"
            onPress={() => handleSizeClick()}>
            Take photo
          </Button>
        </VStack>
      </Center>
    </>
  );
}
