// @ts-nocheck
import React, {useState} from 'react';
import {ScrollView, View} from 'react-native';
import Text from '../../components/Text';
import useTailwind from '../../components/useTailwind';
import {Navbar, Permissions} from '../../components';
import {UnitLink} from '../../@types/UnitLink';
import {deriveDirectoryFromState} from './utils';

const PermissionsScreen = ({navigation, route}) => {
  const unitLink: UnitLink = route.params.unitLink;
  const [unitPeopleState, setUnitPeopleState] = useState({
    ...unitLink,
  });

  const tailwind = useTailwind();


  // TODO: update this to use the new redux state
  function updateDirectoryInfo() {
    const directoryInfo = deriveDirectoryFromState(unitPeopleState);
    console.log('id', unitPeopleState.id);
    console.log('directoryInfo', directoryInfo);
    // dispatch(UpdatePeopleLink(unitPeopleState.id, directoryInfo));
  }

  const handlePermissionsToggle = permissions => {
    const resourcePermissions = {};
    permissions.forEach(permission => {
      resourcePermissions[permission.title.toLowerCase() + 'Permission'] =
        permission.permissions.map(perm => (perm.value ? '1' : '0')).join('');
    });
    setUnitPeopleState({
      ...unitPeopleState,
      ...resourcePermissions,
    });
  };

  return (
    <View style={[tailwind('flex-1 mb-4')]}>
      <Navbar
        navigation={navigation}
        title={`Permissions for unit ${
          unitLink.unitNickname ? unitLink.unitNickname : unitLink.unit.unitName
        }`}
      />
      <>
        <ScrollView
          style={tailwind('mx-2 py-4')}
          showsVerticalScrollIndicator={false}>
          <Permissions
            initialPeopleState={unitPeopleState}
            updateParentState={childState =>
              setUnitPeopleState({
                ...childState,
                firstName: unitPeopleState.firstName,
                lastName: unitPeopleState.lastName,
                city: unitPeopleState.city,
                zip: unitPeopleState.zip,
                state: unitPeopleState.state,
              })
            }
            isEditMode={false}
          />
          {/* <Button
          onPress={updateDirectoryInfo}
          style={[tailwind('mx-2 my-4'), {backgroundColor: '#007bff'}]}
          isLoadingText="updating in ...">
          Update
        </Button> */}
          <Text>Button here</Text>
        </ScrollView>
      </>
    </View>
  );
};

export default PermissionsScreen;
