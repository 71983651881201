// @ts-nocheck
import React, {useState} from 'react';
import {View, FlatList, LogBox, Alert} from 'react-native';
import {ActivityIndicator, Menu, Card} from 'react-native-paper';
import Text from '../../../components/Text';
import useTailwind from '../../../components/useTailwind';
import {useSelector} from 'react-redux';
import {styles} from '../../../styles';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {MySearchbar, NavbarWithAction} from '../../../components';
import {useFocusEffect} from '@react-navigation/native';
import {
  deleteViolationById,
  fetchViolationsByUnit,
} from '../../../api/violation';
import {RootStore} from '../../../redux/store';
import {permissionToBool} from '../../../utils';
import IconButton from '../../../components/IconButton';
import Container from '../../../components/Container';
import Content from '../../../navigation/Content';

const Violations = ({navigation}) => {
  const [violations, setViolations] = useState([]);
  const [violationsHolder, setViolationsHolder] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const defaultUnit = useSelector(
    (state: RootStore) => state.units.unitLinks,
  ).filter(link => link.isDefault === true)[0];
  const [searchTerm, setSearchTerm] = useState('');
  const [isNavbarShown, setIsNavbarShown] = useState(true);
  const {tailwind} = useTailwind();

  const fetchViolations = async unit => {
    setIsLoading(true);
    try {
      const response = await fetchViolationsByUnit(unit);
      setViolations(response);
      setViolationsHolder(response);
    } catch (error) {
      showErrorMessage(error.toString());
    }
    setIsLoading(false);
  };

  const ignoreListsWarnings = () => {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
  };

  useFocusEffect(
    React.useCallback(() => {
      ignoreListsWarnings();
      if (defaultUnit) {
        fetchViolations(defaultUnit.unit.id);
      }
    }, [defaultUnit]),
  );

  const showDeleteViolationPrompt = violationId => {
    Alert.alert(
      '',
      'Do you really want to delete violation ?',
      [
        {
          text: 'NO',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: 'YES',
          onPress: () => deleteViolation(violationId),
        },
      ],
      {
        cancelable: true,
      },
    );
  };

  const deleteViolation = async violationId => {
    try {
      await deleteViolationById(violationId);
      showSuccessMessage('Violation deleted successfully');
    } catch (error) {
      showErrorMessage(error.message);
    }
    await fetchViolations(defaultUnit.id);
  };

  const searchHandler = searchString => {
    setSearchTerm(searchString);
    const newPets = violationsHolder.filter(pet => {
      const clientData = `${pet.petName.toUpperCase()}`;
      const searchTermData = searchString.toUpperCase();
      return clientData.indexOf(searchTermData) > -1;
    });
    setViolations(newPets);
  };

  return (
    <Container>
      <Content>
        {isNavbarShown ? (
          <NavbarWithAction
            navigation={navigation}
            hasBackArrow
            hasSearch={violations.length > 0}
            title="Violations"
            showCurrentUnit
            onClickSearch={() => setIsNavbarShown(false)}
            hasAdd={permissionToBool(
              defaultUnit.violationsPermission?.charAt(3),
            )}
            onClickAdd={() =>
              navigation.navigate('Add Violation', {violation: null})
            }
            fullScreen
          />
        ) : (
          <MySearchbar
            placeholder="Search  name"
            value={searchTerm}
            setValue={val => searchHandler(val)}
            resetSearch={() => {
              setIsNavbarShown(true);
              setSearchTerm('');
              setViolations(violationsHolder);
            }}
          />
        )}
        {isLoading ? (
          <ActivityIndicator
            animating={true}
            color={styles.primaryColor.color}
            style={tailwind('flex-1 items-center content-center')}
          />
        ) : (
          <>
            <FlatList
              data={violations}
              style={tailwind('my-3 mx-2 flex-1 ')}
              ListFooterComponent={<View style={tailwind('h-8')} />}
              ListEmptyComponent={<Text>No violations found</Text>}
              renderItem={({item}) => (
                <ViolationsListItem
                  violation={item}
                  onSelectDelete={() => showDeleteViolationPrompt(item.id)}
                  itemClickHandler={() =>
                    navigation.navigate('Violation Detail', {
                      violationId: item.id,
                    })
                  }
                  onSelectEdit={() =>
                    navigation.navigate('Edit Violation', {violation: item})
                  }
                />
              )}
            />
          </>
        )}
      </Content>
    </Container>
  );
};

const ViolationsListItem = ({
  violation,
  onSelectEdit,
  onSelectDelete,
  itemClickHandler,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const unitLink = useSelector(
    (state: RootStore) => state?.units?.unitLinks,
  ).filter(link => link.isDefault === true)[0];
  const {tailwind} = useTailwind();
  
  return (
    <Card style={tailwind('rounded my-2')} onPress={() => itemClickHandler()}>
      <View style={tailwind('flex-row p-2 my-1 justify-between')}>
        <View style={tailwind('flex-1 ml-2 flex-col justify-around')}>
          <View style={tailwind('flex-row')}>
            <Text style={tailwind('text-lg')}>
              {violation.violationCategory
                ? violation.violationCategory
                : 'Category not provided'}
            </Text>
          </View>
          <Text style={tailwind('')}>
            {violation.specificViolation
              ? violation.specificViolation
              : 'Violation not provided'}
          </Text>
          <View style={tailwind('flex-1 flex-row mt-2')}>
            <View style={tailwind('flex-1 flex-row  justify-between')}>
              <Text>
                {violation.severityOfViolation
                  ? violation.severityOfViolation
                  : 'Severity not provided'}
              </Text>
            </View>
          </View>
        </View>
        {(permissionToBool(unitLink?.violationsPermission.charAt(1)) ||
          permissionToBool(unitLink?.violationsPermission.charAt(4))) && (
          <View style={tailwind('mt-3 mr-4')}>
            <Menu
              visible={menuVisible}
              onDismiss={() => setMenuVisible(false)}
              anchor={
                <IconButton
                  icon="ellipsis-vertical"
                  iconColor={'#718096'}
                  size={24}
                  onPress={() => setMenuVisible(true)}
                />
              }>
              {permissionToBool(unitLink?.violationsPermission.charAt(1)) && (
                <Menu.Item
                  onPress={() => {
                    setMenuVisible(false);
                    onSelectEdit();
                  }}
                  title="Edit"
                />
              )}
              {permissionToBool(unitLink?.violationsPermission.charAt(4)) && (
                <Menu.Item
                  onPress={() => {
                    setMenuVisible(false);
                    onSelectDelete();
                  }}
                  title="Delete"
                />
              )}
            </Menu>
          </View>
        )}
      </View>
    </Card>
  );
};

export default Violations;
