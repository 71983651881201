// @ts-nocheck
import React from 'react';
import {SafeAreaView, ScrollView, View} from 'react-native';
import useTailwind from '../../../components/useTailwind';
import AsterixSwitch from '../../../components/AsterixSwitch';
import Text from '../../../components/Text';
import {styles} from '../../../styles';
import ZoomableImage from '../../../components/ZoomableImage';

const UnitDetails = ({navigation, route}) => {
  const {unit} = route.params;
  const imageUrl = unit.profilePicture
    ? `data:image/jpeg;base64,${unit.profilePicture}`
    : 'https://dummyimage.com/110x88/cccccc/cccccc.png';

const tailwind = useTailwind();

  return (
    <SafeAreaView style={[tailwind('flex-1 pt-4 pb-4')]}>
      <ScrollView style={tailwind('mx-2 mb-4')}>
        <View style={tailwind('mt-2 mx-4')}>
          <ZoomableImage
            title={unit.unit}
            style={styles.detailsImage}
            source={{uri: imageUrl}}
          />
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>Unit :</Text>
              <Text style={tailwind('flex-1')}>{unit.unit}</Text>
            </View>
          </>
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>Unit Display:</Text>
              <Text style={tailwind('flex-1')}>{unit.unitDisplay}</Text>
            </View>
          </>
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>Status :</Text>
              <Text style={tailwind('flex-1')}>{unit.status}</Text>
            </View>
          </>
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>Floor :</Text>
              <Text style={tailwind('flex-1')}>{unit.floor}</Text>
            </View>
          </>
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>Line :</Text>
              <Text style={tailwind('flex-1')}>{unit.line}</Text>
            </View>
          </>
          <View style={tailwind('flex-row flex-1 justify-between mt-2')}>
            <Text style={styles.textLabel}>Community Delinquent:</Text>
            <AsterixSwitch
              style={tailwind('mb-4 self-end')}
              value={unit.communityDelinquent}
            />
          </View>
          <View style={tailwind('flex-row flex-1 justify-between mt-2')}>
            <Text style={styles.textLabel}>Building Delinquent:</Text>
            <AsterixSwitch
              style={tailwind('mb-4 self-end')}
              value={unit.buildingDelinquent}
            />
          </View>
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>Alternate unit :</Text>
              <Text style={tailwind('flex-1')}>{unit.alternateUnit}</Text>
            </View>
          </>
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>Account Number :</Text>
              <Text style={tailwind('flex-1')}>{unit.accountNumber}</Text>
            </View>
          </>
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>Address Line 1 :</Text>
              <Text style={tailwind('flex-1')}>{unit.addressLine1}</Text>
            </View>
          </>
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>Address Line 2 :</Text>
              <Text style={tailwind('flex-1')}>{unit.addressLine2}</Text>
            </View>
          </>
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>City :</Text>
              <Text style={tailwind('flex-1')}>{unit.city}</Text>
            </View>
          </>
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>State :</Text>
              <Text style={tailwind('flex-1')}>{unit.state}</Text>
            </View>
          </>
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>Zip :</Text>
              <Text style={tailwind('flex-1')}>{unit.zip}</Text>
            </View>
          </>
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>Key In Keybox Named :</Text>
              <Text style={tailwind('flex-1')}>{unit.keyInKeyboxNamed}</Text>
            </View>
          </>
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>Key Tag :</Text>
              <Text style={tailwind('flex-1')}>{unit.keyTag}</Text>
            </View>
          </>
          <>
            <View style={tailwind('flex-row  mt-2 mb-4')}>
              <Text style={styles.textLabel}>Number Of Keys On Ring :</Text>
              <Text style={tailwind('flex-1')}>{unit.numberOfKeysOnRing}</Text>
            </View>
          </>
          <>
            <Text style={[styles.textLabel, tailwind('mt-4')]}>Notes:</Text>
            <Text style={tailwind('mb-2')}>{unit.notes}</Text>
          </>
          <>
            <Text style={[styles.textLabel, tailwind('mt-4')]}>
              Directions:
            </Text>
            <Text style={tailwind('mb-2')}>{unit.directions}</Text>
          </>
          <>
            <Text style={[styles.textLabel, tailwind('mt-4')]}>
              Call restriction start time:
            </Text>
            <Text style={tailwind('mb-2')}>
              {unit.startBlockAutomatedCalls}
            </Text>
          </>
          <>
            <Text style={[styles.textLabel, tailwind('mt-4')]}>
              Call restriction end time:
            </Text>
            <Text style={tailwind('mb-2')}>{unit.endBlockautomatedCalls}</Text>
          </>
          <>
            <Text style={[styles.textLabel, tailwind('mt-4')]}>
              Special Instructions
            </Text>
            <Text style={tailwind('mb-2')}>{unit.specialInstructions}</Text>
          </>
          <>
            <Text style={[styles.textLabel, tailwind('mt-4')]}>Unit code</Text>
            <Text style={tailwind('mb-2')}>
              {unit.linkCode ? unit.linkCode : 'No Unit code'}
            </Text>
          </>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default UnitDetails;
