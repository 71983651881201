import React from 'react';
import {
  SafeAreaView,
  ScrollView,
  View,
  useWindowDimensions,
} from 'react-native';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import {HStack, Heading} from 'native-base';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import {useForm} from 'react-hook-form';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import {RegistrationData} from '../types';
import {useDispatch} from 'react-redux';
import {setRegisterData} from '../registerReducer';
import QRScanner from '../../UnitPeopleLink/components/QRScanner';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

const InviteCode = ({navigation}) => {
  const {width} = useWindowDimensions();
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const closeDialog =()=> setDialogOpen(false);

  const {control, handleSubmit, setValue} = useForm({
    defaultValues: {
      code: null,
    },
  });

  const handleCodeSubmit = async values => {
    const registrationData: RegistrationData = {
      linkCode: values.code,
    };
    //@ts-ignore
  
    dispatch(setRegisterData(registrationData));
    navigation.navigate('Dl Capture');
  };

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          {
            flex: 1,
            marginVertical: 24,
          },
          isWeb && {
            backgroundColor: '#ffffff',
            width: width / 3,
          },
        ]}>
        <RegisterActionBar step={4} />
        <ScrollView keyboardShouldPersistTaps="handled">
          <View
            style={{
              marginHorizontal: 24,
              marginTop: 16,
            }}>
            <Heading size="lg">Invite Code</Heading>
            <Text
              style={{
                marginTop: 32,
                fontSize: 18,
              }}>
              Type in the code you received in your welcome to unit email.
            </Text>
            <TextInput
              //@ts-ignore
              control={control}
              name="code"
              placeholder="e.g UC-YQ3R-1N2B"
              label="Invite code"
            />
            <Text style={{fontSize: 24, textAlign: 'center', marginBottom: 10}}>
              or
            </Text>
            {!isWeb && (
              <QRScanner
                setProfileCode={qrCode => {
                  setValue('code', qrCode);
                }}
              />
            )}
            <Button
              variant="ghost"
              onPress={() => navigation.navigate('Dl Capture')}>
              Don't have invite code ?
            </Button>
          </View>
        </ScrollView>
        <HStack style={{margin: 24, marginTop: 32}}>
          <RegisterPrevButton />
          <Button
            onPress={
              !control._formValues.code
                ? () => setDialogOpen(true)
                : handleSubmit(handleCodeSubmit)
            }
            style={{flex: 1}}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
      <ConfirmationDialog
        isOpen={dialogOpen}
        onClose={closeDialog}
        title="No invite code?"
        description="You can still register without an invite code. Would you like to continue?"
        onYesPressed={() => navigation.navigate('Dl Capture')}
        onNoPressed={() => {}}
      />
    </ScreenWrapper>
  );
};

export default InviteCode;
