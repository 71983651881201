import {Toast} from 'native-base';
import {colorSwatches} from '../styles';

export const showErrorMessage = (description: string) => {
  Toast.show({
    title: `${description}`,
    variant: 'solid',
    style: {backgroundColor: colorSwatches.red[400]},
    fontSize: 14,
    placement: 'bottom',
    avoidKeyboard: true,
  });
};

export const showSuccessMessage = (description: string) => {
  Toast.show({
    title: description,
    backgroundColor: '#3AA76D',
    avoidKeyboard: true,
  });
};

export const showInformationMessage = (description: string) => {
  Toast.show({
    title: description,
    avoidKeyboard: true,
  });
};

export const showMessageWithCopy = (description: string) => {
  Toast.show({
    title: description,
    avoidKeyboard: true,
  });
};

export const showPermissionMessage = () => {
  Toast.show({
    title: 'No permission to perform this operation',
    avoidKeyboard: true,
  });
};
