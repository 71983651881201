// @ts-nocheck
/* eslint-disable react-native/no-inline-styles */
import {useNavigation} from '@react-navigation/native';
import React, {useCallback, useState} from 'react';
import {FlatList, RefreshControl, View} from 'react-native';
import Text from '../../../components/Text';
import {useDispatch, useSelector} from 'react-redux';
import useTailwind from '../../../components/useTailwind';
import {LoadingSpinner} from '../../../components';
import {
  DeleteAllAlerts,
  FilterAlerts,
  GetAlerts,
  GetNextAlerts,
} from '../alertsReducer';
import {RootStore} from '../../../redux/store';
import NotificationsItem from '../components/NotificationsItem';
import {isTablet} from 'react-native-device-info';
import Center from '../../../components/Center';
import Divider from '../../../components/Divider';
import Button from '../../../components/Button';
import isWeb from '../../../utils/isWeb';
import ActiveFiltersSection from '../components/ActiveFiltersSection';

const Notifications = () => {
  const {alerts, filterArgs, loading, pageNumber, totalRows, nextPage} =
    useSelector((state: RootStore) => state.alerts);
  const {unitLinksHolder} = useSelector((state: RootStore) => state.units);
  const units: Array<{id: string; name: string}> = unitLinksHolder?.map(
    unitlink => {
      return {
        id: unitlink.unit.id,
        name: unitlink.unitNickname
          ? unitlink.unitNickname
          : unitlink.unit.unitName,
      };
    },
  );
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [refreshing, setRefreshing] = useState(false);
  const tailwind = useTailwind();

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    dispatch(
      FilterAlerts({
        unit: null,
        eventName: null,
        startDate: null,
        endDate: null,
      }),
    );
    dispatch(DeleteAllAlerts());
    dispatch(GetAlerts());
    setRefreshing(false);
  }, []);

  return (
    <>
      {loading && pageNumber === 1 ? (
        <LoadingSpinner />
      ) : (
        <>
          <FlatList
            refreshControl={
              <RefreshControl onRefresh={onRefresh} refreshing={refreshing} />
            }
            data={alerts}
            style={tailwind('pt-2')}
            showsVerticalScrollIndicator={false}
            ListHeaderComponent={
              <ActiveFiltersSection units={units} filterArgs={filterArgs} />
            }
            ListEmptyComponent={
              <Center>
                <Text style={tailwind('mt-16')}>
                  No notifications at this moment, try adjusting your filters
                </Text>
              </Center>
            }
            renderItem={({item}) => (
              <NotificationsItem
                keyExtractor={({id}) => id}
                notification={item}
                clickHandler={() =>
                  navigation.navigate('Notification Details', {
                   isFromTray: false,
                   alertId: null,
                   id: item.id
                  })
                }
                openApproval={() =>
                  navigation.navigate('Approval Detail', {
                    id: item?.approval.id,
                    unitId: item.unit.unitId,
                  })
                }
              />
            )}
            ItemSeparatorComponent={() => (
              <Divider
                style={
                  isWeb
                    ? {
                        width: '98%',
                        marginHorizontal: '1%',
                      }
                    : {
                        width: '80%',
                        marginLeft: isTablet() ? '5%' : '15%',
                      }
                }
              />
            )}
            ListFooterComponent={
              <View
                style={[
                  tailwind('h-32'),
                  {
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                ]}>
                <View
                  style={{
                    marginBottom: 46,
                  }}>
                  {!!nextPage && (
                    <Button
                      onPress={()=> dispatch(GetNextAlerts())}
                      isLoading={loading && pageNumber !== 1}
                      isLoadingText="Loading more ..."
                      isDisabled={loading && pageNumber !== 1}>
                      Load more
                    </Button>
                  )}
                </View>
              </View>
            }
          />
        </>
      )}
    </>
  );
};

export default Notifications;
