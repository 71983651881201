import React from 'react';
import {ScrollView, View} from 'react-native';
import Text from '../../../components/Text';
import {Card, Divider} from 'react-native-paper';
import useTailwind from '../../../components/useTailwind';
import {styles} from '../../../styles';
import {Navbar} from '../../../components';
import {GateCard} from '../../../@types/GateCard';
import Container from '../../../components/Container';

const GateCardDetail = ({navigation, route}) => {
  const gateCard: GateCard = route.params?.gateCard;

  const tailwind = useTailwind();

  return (
    <Container>
      <Navbar navigation={navigation} title="Gatecard details" />
      <ScrollView
        style={tailwind('mx-2 py-4')}
        showsVerticalScrollIndicator={false}>
        <Card style={[tailwind('p-2 mb-8')]}>
          <View style={tailwind('flex-1 flex-row justify-between')} />
          <View style={tailwind('flex-row  my-3')}>
            <Text style={styles.textLabel}>Card number</Text>
            <Text style={styles.detailsText}>{gateCard.cardNumber}</Text>
          </View>
          <Divider />
          <View style={tailwind('flex-row  my-3')}>
            <Text style={styles.textLabel}>Card holder name</Text>
            <Text style={styles.detailsText}>{gateCard.cardHolderName}</Text>
          </View>

          <Divider />
          <View style={tailwind('flex-row  my-3')}>
            <Text style={styles.textLabel}>Printed number</Text>
            <Text style={styles.detailsText}>{gateCard.printedNumber}</Text>
          </View>
          <Divider />
          <View style={tailwind('flex-row  my-3')}>
            <Text style={styles.textLabel}>User type</Text>
            <Text style={styles.detailsText}>{gateCard.userType}</Text>
          </View>
          <Divider />
          <View style={tailwind('flex-row my-3')}>
            <Text style={styles.textLabel}>Type</Text>
            <Text style={styles.detailsText}>{gateCard.type}</Text>
          </View>
          <Divider />
          <View style={tailwind('flex-row  my-3')}>
            <Text style={styles.textLabel}>Disabled</Text>
            <Text style={styles.detailsText}>
              {gateCard.disabled ? 'True' : 'False'}
            </Text>
          </View>
          {gateCard.disabled === true && (
            <>
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Disabled message</Text>
                <Text style={styles.detailsText}>
                  {gateCard.diabledMessage}
                </Text>
              </View>
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Disabled date</Text>
                <Text style={styles.detailsText}>{gateCard.disabledDate}</Text>
              </View>
            </>
          )}
          <Divider />
          <View style={tailwind('flex-row  my-3')}>
            <Text style={styles.textLabel}>Always take picture</Text>
            <Text style={styles.detailsText}>
              {gateCard.alwaysTakePicture ? 'True' : 'False'}
            </Text>
          </View>
          <Divider />
          <View style={tailwind('flex-row  my-3')}>
            <Text style={styles.textLabel}>Issued Date</Text>
            <Text style={styles.detailsText}>
              {new Date(gateCard.issuedDate).toLocaleDateString()}
            </Text>
          </View>
          <Divider />
          <View style={tailwind('flex-row  my-3')}>
            <Text style={styles.textLabel}>Return date</Text>
            <Text style={styles.detailsText}>{gateCard.returnDate}</Text>
          </View>
          <Divider />
          <View style={tailwind('flex-row  my-3')}>
            <Text style={styles.textLabel}>Comments</Text>
            <Text style={styles.detailsText}>{gateCard.comments}</Text>
          </View>
        </Card>
      </ScrollView>
    </Container>
  );
};

export default GateCardDetail;
