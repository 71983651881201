// @ts-nocheck
import {Text} from 'react-native';
import useTailwind from '../../../components/useTailwind';
import React from 'react';
import getConfig from '../../../utils/getConfig';

export default () => {
  const environment = getConfig('MY_WORKS_ENVIRONMENT');
  const apiVersion = getConfig('MY_WORKS_API_SUFFIX');
  const tailwind = useTailwind();

  return (
    <Text style={[tailwind('text-center text-xs'), {color: '#718096'}]}>
      {`V1.0.14-29.11.2024 ${environment} ${apiVersion}`}
    </Text>
  );
};