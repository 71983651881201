import React, {useState, useEffect} from 'react';
import {View, ScrollView, useWindowDimensions} from 'react-native';
import Text from '../../../components/Text';
import useTailwind from '../../../components/useTailwind';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {showErrorMessage} from '../../../service/flashMessage';
import {createUnitPeopleConnector} from '../../../api/connector';
import {Navbar} from '../../../components';
import {initialUnitPeopleConnectorState} from '../utils';
import {useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import {ActivityIndicator} from 'react-native-paper';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import ScreenWrapper from '../../Auth/components/ScreenWrapper';
import {copyToClipboard} from '../../../service/clipboard';
import isWeb from '../../../utils/isWeb';

const LinkCodeGenerator = ({navigation}) => {
  const personId = useSelector((state: RootStore) => state.auth.user.id);
  // const unitId = useSelector((state) => state.defaultUnit).id;
  const [unitPeopleConnectorState, setUnitPeopleConnectorState] = useState({
    ...initialUnitPeopleConnectorState,
    peopleId: personId,
    // unitId: unitId,
  });
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [linkCode, setLinkCode] = useState('');

  const {width} = useWindowDimensions();
  const tailwind = useTailwind();

  useEffect(() => {
    submitUnitPeopleConnector(unitPeopleConnectorState);
  }, [unitPeopleConnectorState]);

  const submitUnitPeopleConnector = async connector => {
    try {
      const link = await createUnitPeopleConnector(connector);
      setLinkCode(link.linkCode);
    } catch (e) {
      showErrorMessage(e.message);
    }
    setIsSubmitting(false);
  };

  return (
    <View style={[tailwind('flex-1 mb-2')]}>
      <Navbar navigation={navigation} title="People link code" />
      <ScreenWrapper>
        {isSubmitting ? (
          <ActivityIndicator />
        ) : (
          <ScrollView
            style={[
              isWeb && {
                flex: 1,
                margin: 16,
                width: width / 3,
                backgroundColor: '#ffffff',
                paddingHorizontal: 32,
              },
              {
                margin: 16,
                flex: 1,
              },
            ]}>
            <>
              <Text style={tailwind('flex-1 mt-6')}>Your code is:</Text>
              <Input
                mt={4}
                mb={4}
                value={linkCode}
                isReadOnly={true}
                size="lg"
                px={3}
                InputRightElement={
                  <Button
                    variant="ghost"
                    startIcon={
                      <MaterialCommunityIcons
                        name="content-paste"
                        color="gray"
                        size={24}
                      />
                    }
                    onPress={() => {
                      copyToClipboard(linkCode);
                    }}>
                    {`Copy `}
                  </Button>
                }
                style={{
                  fontFamily: 'RobotoMono-Regular',
                  fontSize: 24,
                  textAlign: 'center',
                }}
              />
              <Text style={tailwind('text-xl mt-12 mb-2')}>
                This code is typically given to a resident with permission to
                add visitors or vendors in their unit.
              </Text>
              <Text style={tailwind('text-xl mb-2')}>
                They use this code to link you to their unit. once linked you
                can see if you have permission to enter and request access if
                you don not.
              </Text>
              <Text style={tailwind('text-xl mb-2')}>
                This code is invalid after 24 hours
              </Text>
            </>
          </ScrollView>
        )}
      </ScreenWrapper>
    </View>
  );
};

export default LinkCodeGenerator;
