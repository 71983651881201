import React from 'react';
import Container from '../../components/Container';
import {Navbar} from '../../components';
import {useNavigation} from '@react-navigation/native';
import {Card} from 'react-native-paper';
import {Image, Pressable, ScrollView, View} from 'react-native';
import Text from '../../components/Text';
import FeatherIcon from 'react-native-vector-icons/Feather';
import useTailwind from '../../components/useTailwind';
import Box from '../../components/Box';
import useColorModeValue from '../../components/useColorModeValue';
import VersionCode from '../../modules/Auth/components/VersionCode';
import {initiateSendEmail, openUrl} from '../../modules/App/service';

const About = () => {
  const navigation = useNavigation();
  const tailwind  = useTailwind();

  return (
    <Container>
      <Navbar navigation={navigation} title="About" />
      <ScrollView showsHorizontalScrollIndicator={false}>
        <Card style={tailwind('mx-2 mt-4')}>
          <Box
            bg={useColorModeValue('gray.50', 'gray.700')}
            style={tailwind('pb-2')}>
            <Pressable
              style={tailwind(
                'flex-row py-3 px-2 justify-between items-center',
              )}>
              <View style={{flexDirection: 'row'}}>
                <FeatherIcon name="info" color="#718096" size={20} />
                <Text style={tailwind('text-lg px-2')}>Version</Text>
              </View>
              <VersionCode />
            </Pressable>
            <Pressable
              onPress={() =>
                initiateSendEmail('info@asterixsoftware.com', '', '')
              }
              style={tailwind('flex-row py-3 px-2 ')}>
              <FeatherIcon name="mail" color="#718096" size={20} />
              <Text style={tailwind('text-lg px-2')}>Email</Text>
            </Pressable>
            <Pressable
              style={tailwind('flex-row py-3 px-2')}
              onPress={() => openUrl('https://www.asterixsoftware.com')}>
              <FeatherIcon name="globe" color="#718096" size={20} />
              <Text style={tailwind('text-lg px-2')}>Website</Text>
            </Pressable>
            <Pressable
              style={tailwind('flex-row py-3 px-2')}
              onPress={() =>
                openUrl('https://www.youtube.com/@AsterixSoftware')
              }>
              <FeatherIcon name="youtube" color="#718096" size={20} />
              <Text style={tailwind('text-lg px-2')}>Youtube</Text>
            </Pressable>
          </Box>
        </Card>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Image
            resizeMode="center"
            source={require('../../images/asterix_logo.png')}
            style={{}}
          />
        </View>
      </ScrollView>
    </Container>
  );
};

export default About;
