// @ts-nocheck
import React, {useState} from 'react';
import {View} from 'react-native';
import {Approval} from '../../../@types/Approval';
import {Card, TouchableRipple} from 'react-native-paper';
import useTailwind from '../../../components/useTailwind';
import {ApprovalSection, LoadingSpinner} from '../../../components';
import {useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {residentApiWithAuth} from '../../../api/resident';
import Text from '../../../components/Text';

export default function ApprovalItem({
  approval,
  approvalClickHandler,
  refreshList,
}: {
  approval: Approval;
  approvalClickHandler: Function;
}) {
  const [_approval, _setApproval] = useState(approval);
  //if this person's unitlink is allowed to approve for this unit, show  buttons
  const unitLink = useSelector(
    (state: RootStore) => state.units.unitLinks,
  )?.filter(link => link.unit.id === _approval?.unit?.unitId)[0];
  const [requiresResidentApproval, setRequiresResidentApproval] = useState(
    unitLink?.allowedtoApprove &&
      _approval.residentApprovalStatus === 'Pending',
  );
  const [loading, setLoading] = useState(false);
  const {user} = useSelector((state: RootStore) => state.auth);

  const tailwind = useTailwind();

  //Refresh approval history on [Approve, Deny]
  async function handleApprove(id: string, approve: boolean) {
    setLoading(true);
    try {
      const res = await residentApiWithAuth().post('/approvals', {
        id,
        approve,
      });
      const result = res.data;
      console.log('results', result);
      if (result.errors) {
        showErrorMessage(result.errors[0]);
      } else {
       !!refreshList  && refreshList();
        _setApproval({
          ...approval,
          residentApprovalStatus: approve ? 'Approved' : 'Denied',
          residentApprovalDateTime: new Date().toISOString(),
          residentApprovalName: `${user.firstName} ${user.lastName}`,
        });
        setRequiresResidentApproval(false);
        showSuccessMessage('Your response has been sent');
      }
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card
      onPress={() => approvalClickHandler()}
      style={tailwind('rounded m-2')}>
      <View style={tailwind('flex-col p-2 justify-between')}>
        <Text>{`Unit ${_approval.unit.unitName}`}</Text>
        <View style={tailwind('flex-row justify-between')}>
          <View style={tailwind('flex-row')}>
            <Text style={tailwind('text-xs')}>
              {`${_approval?.type?.toUpperCase()} - ${
                _approval?.message !== null ? _approval.description : ''
              }`}
            </Text>
          </View>
        </View>
        {_approval?.message !== null && <Text>{_approval.message}</Text>}
        <Text
          style={tailwind(
            'mt-1 text-sm',
          )}>{`Requested by ${_approval.approvalRequesterName}`}</Text>
        <Text style={tailwind('mt-1 text-sm')}>{`${
          _approval.approvalRequesteeName
            ? `Requestee ${_approval.approvalRequesteeName}`
            : 'No requestee'
        }`}</Text>
        <ApprovalSection approval={_approval} />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {requiresResidentApproval && (
              <View style={tailwind('flex-row justify-between mt-4 mb-2')}>
                <TouchableRipple
                  onPress={() => handleApprove(_approval.id, true)}>
                  <View
                    style={tailwind('flex-row px-2 py-2 bg-green-400 rounded')}>
                    <Text style={tailwind('text-white text-center mr-2')}>
                      Approve
                    </Text>
                  </View>
                </TouchableRipple>
                <TouchableRipple
                  onPress={() => handleApprove(_approval.id, false)}>
                  <View
                    style={tailwind('flex-row px-2 py-2 bg-red-400 rounded')}>
                    <Text style={tailwind('text-white text-center mr-2 ')}>
                      Not Approve
                    </Text>
                  </View>
                </TouchableRipple>
              </View>
            )}
          </>
        )}

        <Text numberOfLines={1} style={tailwind('self-end mt-1 text-xs')}>
          {new Date(_approval.approvalRequestDateTime).toLocaleString()}
        </Text>
      </View>
    </Card>
  );
}
