// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {
  FlatList,
  ActivityIndicator,
  RefreshControl,
  View,
  Alert,
} from 'react-native';
import Text from '../../../components/Text';
import {useDispatch, useSelector} from 'react-redux';
import useTailwind from '../../../components/useTailwind';
import {MySearchbar, NavbarWithAction} from '../../../components';

import {RootStore} from '../../../redux/store';
import {useNavigation} from '@react-navigation/native';
import Center from '../../../components/Center';
import {styles} from '../../../styles';
import {Card, Menu} from 'react-native-paper';
import {DeleteStorage, GetStorages, StorageSearch} from '../storagesReducer';
import {useCallback} from 'react';
import {Storage} from '../types';
import {permissionToBool} from '../../../utils';
import IconButton from '../../../components/IconButton';
import Container from '../../../components/Container';

const Storages: React.FC = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const {storages, loading} = useSelector((state: RootStore) => state.storages);
  const {unitLinks} = useSelector((state: RootStore) => state.units);
  const currentUnitLink = unitLinks.find(link => link.isDefault === true);
  const [isNavbarShown, setIsNavbarShown] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [refreshing, setRefreshing] = useState(false);

  const tailwind = useTailwind();

  useEffect(() => {
    dispatch(GetStorages());
  }, []);

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    dispatch(GetStorages());
    setRefreshing(false);
  }, []);

  const showDeletePrompt = (storageId: string) => {
    Alert.alert(
      '',
      'Do you really want to delete storage ?',
      [
        {
          text: 'NO',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: 'YES',
          onPress: () => dispatch(DeleteStorage(storageId)),
        },
      ],
      {
        cancelable: true,
      },
    );
  };

  return (
    <Container>
      {isNavbarShown ? (
        <NavbarWithAction
          navigation={navigation}
          title="Storages"
          hasBackArrow
          showCurrentUnit
          hasAdd={permissionToBool(
            currentUnitLink?.storagePermission?.charAt(3),
          )}
          hasSignOut={false}
          onClickSearch={() => setIsNavbarShown(false)}
          hasSearch={storages?.length > 0}
          onClickAdd={() => navigation.navigate('Add Storage')}
          showUnitPicture={false}
          onClickFilter={() => null}
          hasFilter={false}
          permissionSource="storagePermission"
          fullScreen
        />
      ) : (
        <MySearchbar
          placeholder="Search by bin location"
          value={searchTerm}
          setValue={(val: string) => {
            setSearchTerm(val);
            dispatch(StorageSearch(val));
          }}
          resetSearch={() => {
            setIsNavbarShown(true);
            setSearchTerm('');
            dispatch(StorageSearch(''));
          }}
        />
      )}
      {loading ? (
        <ActivityIndicator
          animating={true}
          color={styles.primaryColor.color}
          style={tailwind('flex-1 items-center content-center')}
        />
      ) : (
        <FlatList
          refreshControl={
            <RefreshControl onRefresh={onRefresh} refreshing={refreshing} />
          }
          data={storages}
          style={tailwind('my-3 mx-2 flex-1 ')}
          ListFooterComponent={<View style={tailwind('h-8')} />}
          ListEmptyComponent={<Center>No storages found</Center>}
          renderItem={({item}) => (
            <StorageItem
              key={item.id}
              storage={item}
              onSelectDelete={() => {
                showDeletePrompt(item.id);
              }}
              itemClickHandler={() =>
                navigation.navigate('Storage Detail', {storage: item})
              }
              onSelectEdit={() =>
                navigation.navigate('Edit Storage', {storage: item})
              }
              currentUnitLink={currentUnitLink}
            />
          )}
        />
      )}
    </Container>
  );
};

const StorageItem = ({
  storage,
  onSelectEdit,
  onSelectDelete,
  itemClickHandler,
  currentUnitLink,
}: {
  storage: Storage;
  itemClickHandler: () => void;
  onSelectDelete: () => void;
  onSelectEdit: () => void;
  currentUnitLink: UnitLink;
}) => {
  const tailwind = useTailwind();
  const [menuVisible, setMenuVisible] = useState(false);
  return (
    <Card style={tailwind('rounded my-2')} onPress={() => itemClickHandler()}>
      <View style={tailwind('flex-row p-2 my-1 justify-between')}>
        <View style={tailwind('flex-1 ml-2 flex-col justify-around')}>
          <View style={tailwind('flex-row')}>
            <Text style={tailwind('text-lg')}>{storage.storageBin}</Text>
          </View>
          <Text>{storage.binLocation}</Text>
          <Text>{storage.monthlyCost?.toString()}</Text>
          <View style={tailwind('flex-1 flex-row mt-2 justify-between')}>
            <Text>
              {new Date(storage.dateFrame.startDate).toLocaleDateString()} .{' '}
              {new Date(storage.dateFrame.endDate).toLocaleDateString()}
            </Text>
          </View>
        </View>
        {(permissionToBool(currentUnitLink?.storagePermission.charAt(1)) ||
          permissionToBool(currentUnitLink?.storagePermission.charAt(4))) && (
          <View style={tailwind('mt-3 mr-4')}>
            <Menu
              visible={menuVisible}
              onDismiss={() => setMenuVisible(false)}
              anchor={
                <IconButton
                  icon="ellipsis-vertical"
                  iconColor={'#718096'}
                  size={24}
                  onPress={() => setMenuVisible(true)}
                />
              }>
              {permissionToBool(
                currentUnitLink?.storagePermission.charAt(1),
              ) && (
                <Menu.Item
                  onPress={() => {
                    setMenuVisible(false);
                    onSelectEdit();
                  }}
                  title="Edit"
                />
              )}
              {permissionToBool(
                currentUnitLink?.storagePermission.charAt(4),
              ) && (
                <Menu.Item
                  onPress={() => {
                    setMenuVisible(false);
                    onSelectDelete();
                  }}
                  title="Delete"
                />
              )}
            </Menu>
          </View>
        )}
      </View>
    </Card>
  );
};

export default Storages;
