import React from 'react';
import {FlatList, View} from 'react-native';
import useTailwind from '../../../components/useTailwind';
import {Navbar} from '../../../components';
import ApprovalItem from '../../Alerts/components/ApprovalItem';

export default function ApprovalsHistory({navigation, route}) {
  const {personName, isLoggedInUser, approvals, type, recordName} =
    route?.params;
  const extraTitlePart = `${
    type === 'history' ? 'approvals history' : 'pending approvals'
  }`;
  const TITLE = `${
    isLoggedInUser ? 'Your' : `${personName}'s`
  } ${extraTitlePart} for ${recordName}`;

  const tailwind = useTailwind();

  return (
    <View style={tailwind('flex-1')}>
      <Navbar navigation={navigation} title={TITLE} />
      <FlatList
        style={tailwind('flex-1 pt-2')}
        data={approvals}
        renderItem={({item}) => (
          <ApprovalItem
            approval={item}
            approvalClickHandler={() =>
              navigation.navigate('Approval Detail', {
                id: item.id,
                unitId: item.unitId,
              })
            }
          />
        )}
      />
    </View>
  );
}
