// @ts-nocheck
import React from 'react';
import {FlatList, View} from 'react-native';
import Text from '../../../components/Text';
import {useNavigation} from '@react-navigation/native';
import {MySearchbar, NavbarWithAction} from '../../../components';
import {useDispatch, useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import {Card} from 'react-native-paper';
import {UpdateListEvents} from '../../../redux/reducers/listEventsReducer';
import {ListEvent} from '../../../@types';
import {GetAlerts} from '../alertsReducer';
import Button from '../../../components/Button';
import Center from '../../../components/Center';

//We need to store a draftState,
//Initial state is listEvents from store,
//When switch is toggled, update the relevant key in listEvents,
//On submit, update listEvents in store, also need to do the update in local storage
//listEvents always need to be stored locally

export default function Preferences() {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [isNavbarShown, setIsNavbarShown] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState('');
  const listEvents = useSelector(
    (state: RootStore) => state.listEvents.listEvents,
  );
  const [listEventsDraftState, setListEventsDraftState] =
    React.useState<Array<ListEvent>>(listEvents);

  function searchPreferences(searchString: string) {
    const events = listEventsDraftState.filter(event => {
      const descriptionData = event.eventDescription
        ? `${event.eventDescription}`.toUpperCase()
        : `${event.eventName}`.toUpperCase();

      const searchTermData = searchString.toUpperCase();
      return descriptionData.indexOf(searchTermData) > -1;
    });

    setListEventsDraftState(events);
  }

  function HeaderSection() {
    if (isNavbarShown) {
      return (
        <NavbarWithAction
          navigation={navigation}
          hasBackArrow
          title="Notification preferences"
        />
      );
    }
    return (
      <MySearchbar
        placeholder="Search  name"
        setValue={(val: string) => {
          setSearchTerm(val);
        }}
        resetSearch={() => {
          setIsNavbarShown(true);
          setSearchTerm('');
        }}
      />
    );
  }

  function updateListEvent(item: ListEvent) {
    const newState = listEventsDraftState.map(event => {
      if (event.id === item.id) {
        return {
          ...event,
          active: !item.active,
        };
      }
      return event;
    });

    setListEventsDraftState([...newState]);
  }

  function ListingSection() {
    return (
      <FlatList
        data={listEventsDraftState}
        ListFooterComponent={<View style={{height: 32}} />}
        ListEmptyComponent={<Center>No preferences found</Center>}
        renderItem={({item}) => (
          <Card
            style={{
              padding: 8,
            }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <View
                style={{
                  flexDirection: 'column',
                  flex: 1,
                }}>
                <Text style={{fontSize: 18}}>
                  {item.eventName || 'Unnamed'}
                </Text>
                <Text>{item.eventDescription}</Text>
              </View>
              {/* <Switch
                isChecked={item.active}
                onToggle={() => {
                  if (item.active) {
                    showAlert(
                      `Are you sure you wish to turn off ${
                        item.eventName || 'Unnamed'
                      } alerts ?`,
                      `This will hide ${
                        item.eventName || 'Unnamed'
                      } alerts from your alerts list.`,
                      () => console.log(),
                      () => updateListEvent(item),
                    );

                    return;
                  }
                  updateListEvent(item);
                }}
              /> */}
            </View>
          </Card>
        )}
      />
    );
  }

  return (
    <View style={{flex: 1}}>
      <HeaderSection />
      <ListingSection />
      <Button
        onPress={() => {
          dispatch(UpdateListEvents(listEventsDraftState));
          setTimeout(() => {
            dispatch(GetAlerts());
            navigation.goBack();
          }, 1000);
        }}
        mb="4"
        py="4"
        mx="2">
        Save
      </Button>
    </View>
  );
}
