import {View} from 'react-native';
import Text from '../../../components/Text';
import React from 'react';
import {Card} from 'react-native-paper';
import useTailwind from '../../../components/useTailwind';
import {KeyLog} from '../types';

export default function KeyLogItem({
  keyLog,
  itemClickHandler,
}: {
  keyLog: KeyLog;
  itemClickHandler: () => void;
}) {
  const keyIsOut = keyLog.status === 'Out';
  const keyIsReturned = keyLog.status === 'Returned';

  const tailwind = useTailwind();

  return (
    <Card style={tailwind('rounded my-2')} onPress={() => itemClickHandler()}>
      <View style={tailwind('flex-row p-2 my-1 justify-between')}>
        <View style={tailwind('flex-1 ml-2 flex-col justify-around')}>
          <Text>{`Status - ${keyLog.status}`}</Text>
          <Text>{`${keyLog.keysOnRing} Keys on ring `}</Text>
          {keyIsOut && (
            <>
              <Text>
                {`Give by ${keyLog.whoGaveKey?.firstName} ${
                  keyLog.whoGaveKey?.lastName
                } to ${keyLog.keyGivenTo} on ${new Date(
                  keyLog.keyDateTimeOut,
                ).toLocaleDateString()}`}
              </Text>
              <Text>
                {`To be returned before ${new Date(
                  keyLog.expectedReturnDatTime,
                ).toLocaleDateString()}`}
              </Text>
            </>
          )}
          {keyIsReturned && (
            <Text>
              {`Returned  by ${keyLog.whoPutKeyBack?.firstName} ${
                keyLog.whoPutKeyBack?.lastName
              } to ${keyLog.keyGivenTo} on ${new Date(
                keyLog.keyDateTimeIn,
              ).toLocaleDateString()}`}
            </Text>
          )}
        </View>
      </View>
    </Card>
  );
}
