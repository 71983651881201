var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.register = register;
exports.sendNotification = sendNotification;
exports.unregister = unregister;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var isLocalhost = Boolean(window.location.hostname === 'localhost' || window.location.hostname === '[::1]' || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));
function register(config) {
  if ('serviceWorker' in navigator) {
    var publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }
    window.addEventListener('load', function () {
      var swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
        navigator.serviceWorker.ready.then(function () {
          console.log('This web app is being served cache-first by a service ' + 'worker. To learn more, visit https://bit.ly/CRA-PWA');
        });
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}
function registerValidSW(swUrl, config) {
  navigator.serviceWorker.register(swUrl).then(function (registration) {
    registration.onupdatefound = function () {
      var installingWorker = registration.installing;
      if (installingWorker == null) {
        return;
      }
      installingWorker.onstatechange = function () {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            console.log('New content is available and will be used when all ' + 'tabs for this page are closed. See https://bit.ly/CRA-PWA.');
            if (config && config.onUpdate) {
              config.onUpdate(registration);
            }
          } else {
            console.log('Content is cached for offline use.');
            if (config && config.onSuccess) {
              config.onSuccess(registration);
            }
          }
        }
      };
    };
  }).catch(function (error) {
    console.error('Error during service worker registration:', error);
  });
}
function checkValidServiceWorker(swUrl, config) {
  console.log("checkValidServiceWorker......");
  fetch(swUrl, {
    headers: {
      'Service-Worker': 'script'
    }
  }).then(function (response) {
    var contentType = response.headers.get('content-type');
    if (response.status === 404 || contentType != null && contentType.indexOf('javascript') === -1) {
      navigator.serviceWorker.ready.then(function (registration) {
        registration.unregister().then(function () {
          window.location.reload();
        });
      });
    } else {
      registerValidSW(swUrl, config);
    }
  }).catch(function () {
    console.log('No internet connection found. App is running in offline mode.');
  });
}
function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(function (registration) {
      registration.unregister();
    }).catch(function (error) {
      console.error(error.message);
    });
  }
}
function check() {
  if (!('serviceWorker' in navigator)) {
    throw new Error('No Service Worker support!');
  }
  if (!('PushManager' in window)) {
    throw new Error('No Push API Support!');
  }
}
function requestNotificationPermission() {
  return _requestNotificationPermission.apply(this, arguments);
}
function _requestNotificationPermission() {
  _requestNotificationPermission = (0, _asyncToGenerator2.default)(function* () {
    var permission = yield window.Notification.requestPermission();
    console.log("permission", permission);
    if (permission !== 'granted') {
      throw new Error('Permission not granted for Notification');
    }
  });
  return _requestNotificationPermission.apply(this, arguments);
}
function sendNotification(_x, _x2) {
  return _sendNotification.apply(this, arguments);
}
function _sendNotification() {
  _sendNotification = (0, _asyncToGenerator2.default)(function* (title, message) {
    try {
      check();
      var swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      var swRegisration = yield navigator.serviceWorker.register(swUrl);
      var permission = yield requestNotificationPermission();
      var notification = new window.Notification(title, {
        body: message
      });
      console.log("notification", notification);
      console.log("swRegisration", swRegisration);
    } catch (error) {
      console.log("Error", error);
    }
  });
  return _sendNotification.apply(this, arguments);
}
window.sendNotification = sendNotification;