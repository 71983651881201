import React from 'react';
import {View} from 'react-native';
import HStack from './HStack';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {Approval} from '../@types/Approval';
import {buildApprovalMessage} from '../utils';
import Text from '../components/Text';
import useTailwind from '../components/useTailwind';

export default function ApprovalSection({approval}: {approval: Approval}) {
  const {residentMessage, managementMessage} = buildApprovalMessage(approval);

  const residentIconObj = buildIcons(approval?.residentApprovalStatus);
  const managementIconObj = buildIcons(approval?.managementApprovalStatus);
  const tailwind = useTailwind();

  return (
    <View style={tailwind('m-1')}>
      <HStack>
        <Ionicons
          name={residentIconObj.icon}
          size={17}
          color={residentIconObj.color}
          style={tailwind('mr-2')}
        />
        <Text style={tailwind('text-sm')}>{residentMessage}</Text>
      </HStack>
      <HStack>
        <Ionicons
          name={managementIconObj.icon}
          size={17}
          color={managementIconObj.color}
          style={tailwind('mr-2')}
        />
        <Text style={tailwind('text-sm')}>{managementMessage}</Text>
      </HStack>
    </View>
  );
}

const buildIcons = (approvalStatus: string) => {
  const defaultIconObj = {
    icon: 'help-outline',
    color: '#FBBF24',
  };

  switch (approvalStatus) {
    case 'Pending':
      return {
        icon: 'warning',
        color: '#FBBF24',
      };
    case 'Denied':
      return {
        icon: 'close-circle-outline',
        color: 'red',
      };
    case 'Approved':
      return {
        icon: 'checkmark-circle-outline',
        color: 'green',
      };
    default:
      return defaultIconObj;
  }
};
