/* eslint-disable react-native/no-inline-styles */
import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {Image, View, useWindowDimensions} from 'react-native';
import {Navbar} from '../../components';
import PictureZoomHeader from './PictureZoomHeader';

export default function PictureZoom({route}) {
  const navigation = useNavigation();
  const {title, source} = route.params;
  const {width, height} = useWindowDimensions();

  return (
    <View style={{flex: 1}}>
      <Navbar navigation={navigation} title={title} />
      <PictureZoomHeader />
      {/* @ts-ignore */}
      {/* <ImageZoom cropWidth={width} cropHeight={height} imageWidth={200} imageHeight={200} > */}
      <Image style={{width: '100%', height: 500}} source={source} />
      {/* </ImageZoom> */}
    </View>
  );
}
