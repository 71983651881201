// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react';
import {FlatList, View} from 'react-native';
import Text from '../../../components/Text';
import useTailwind from '../../../components/useTailwind';

import {fetchLinksByUnit} from '../../../api/links';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Searchbar} from 'react-native-paper';
import {styles} from '../../../styles';
import {RootStore} from '../../../redux/store';
import {LoadingSpinner} from '../../../components';
import {E_NAVIGATION_DESTINATIONS} from '../../../@types';
import DirectoryItem from '../components/DirectoryItem';
import Container from '../../../components/Container';
import Content from '../../../navigation/Content';

const Directory = ({navigation}) => {
  const [isLoading, setIsLoading] = useState(true);
  const unitId = useSelector(
    (state: RootStore) => state.units.unitLinks,
  ).filter(link => link.isDefault === true)[0].unit.id;
  const [people, setPeople] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const personId = useSelector((state: RootStore) => state.auth.user.id);
  const [peopleHolder, setPeopleHolder] = useState([]);
  const [searchShown, setSearchShown] = useState(true);
  const [resultsShown, setResultsShown] = useState(false);

  const tailwind = useTailwind();

  const searchHandler = (searchString: string) => {
    setSearchTerm(searchString);
    const newPeople = peopleHolder.filter(personLink => {
      const firstName = personLink.person.firstName
        ? `${personLink.person.firstName}`.toUpperCase()
        : ''.toUpperCase();
      const middleName = personLink.person.middleName
        ? `${personLink.person.middleName}`.toUpperCase()
        : ''.toUpperCase();
      const lastName = personLink.person.lastName
        ? `${personLink.person.lastName}`.toUpperCase()
        : ''.toUpperCase();
      const nickName = personLink.person.nickname
        ? `${personLink.person.nickname}`.toUpperCase()
        : ''.toUpperCase();
      const phone = personLink.person.phone
        ? `${personLink.person.phone}`.toUpperCase()
        : ''.toUpperCase();
      const searchTermData = searchString.toUpperCase();
      return (
        firstName.indexOf(searchTermData) > -1 ||
        middleName.indexOf(searchTermData) > -1 ||
        lastName.indexOf(searchTermData) > -1 ||
        nickName.indexOf(searchTermData) > -1 ||
        phone.indexOf(searchTermData) > -1
      );
    });
    setPeople(newPeople);
    setResultsShown(true);
  };

  useEffect(() => {
    fetchPeopleLinks();
  }, []);

  const fetchPeopleLinks = async () => {
    const peopleInUnit = await fetchLinksByUnit(unitId);
    setPeople(peopleInUnit);
    setPeopleHolder(peopleInUnit);
    setIsLoading(false);
  };

  const onTraileringIconPress = () => {
    navigation.navigate(E_NAVIGATION_DESTINATIONS.EDIT_DIRECTORY_SETTINGS);
  };

  return (
    <Container>
      <Content>
        <HeaderComponent
          value={searchTerm}
          searchShown={searchShown}
          searchHandler={(searchText: string) => {
            searchHandler(searchText);
          }}
          onBackPressed={() => navigation.goBack()}
          onTraileringIconPress={onTraileringIconPress}
        />

        {resultsShown && (
          <FlatList
            data={people}
            style={tailwind('mx-2')}
            ListFooterComponent={<View style={tailwind('h-8')} />}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({item}) => (
              <DirectoryItem
                key={item.person.id}
                unitPeopleLink={item}
                currentUser={personId}
              />
            )}
            ListEmptyComponent={() => <Text>{'No person found'}</Text>}
          />
        )}
        {isLoading && <LoadingSpinner />}
      </Content>
    </Container>
  );
};

const HeaderComponent = ({
  value,
  searchHandler,
  onBackPressed,
  searchShown,
  onTraileringIconPress,
}) => {
  return (
    <>
      {searchShown ? (
        <Searchbar
          placeholder={'Search by name,address,phone'}
          autoFocus
          style={styles.searchBar}
          onChangeText={text => searchHandler(text)}
          value={value}
          inputContainerStyle={styles.searchInput}
          lightTheme
          icon={'arrow-back'}
          onIconPress={() => onBackPressed()}
          traileringIcon={'settings'}
          onTraileringIconPress={onTraileringIconPress}
        />
      ) : null}
    </>
  );
};

export default Directory;
