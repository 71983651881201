// @ts-nocheck
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {FlatList} from 'react-native';
import useTailwind from '../../../components/useTailwind';
import {E_NAVIGATION_DESTINATIONS} from '../../../@types';
import {EmptyList, LoadingSpinner, NavbarWithAction} from '../../../components';
import {usePaginatedKeyLogsByUnit} from '../../../hooks/keylogs';
import KeyLogItem from '../components/KeyLogsItem';
import Container from '../../../components/Container';
import FilterPanel from '../components/FilterPanel';

export default function KeyLogs() {
  const navigation = useNavigation();
  const [panelActive, setPanelActive] = useState(false);
  const initialQueryParams = {
    status: null,
  };
  const [queryParams, setQueryParams] = useState(initialQueryParams);

  const {
    isLoading,
    data,
    refetch,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = usePaginatedKeyLogsByUnit(queryParams);

  const tailwind = useTailwind();

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const enabledRef = React.useRef(false);

  useFocusEffect(
    React.useCallback(() => {
      if (enabledRef.current) {
        refetch();
      } else {
        enabledRef.current = true;
      }
    }, [refetch]),
  );

  useEffect(() => {
    refetch();
  }, [queryParams]);

  return (
    <Container>
      <NavbarWithAction
        navigation={navigation}
        hasBackArrow
        title="Key Logs"
        showCurrentUnit
        showUnitPicture={false}
        fullScreen
        hasFilter
        onClickFilter={() => setPanelActive(true)}
      />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <FlatList
          contentContainerStyle={tailwind('p-4')}
          data={data?.pages.map(pageItem => pageItem.data).flat()}
          ListEmptyComponent={
            <EmptyList message="No keylogs for you at the moment" />
          }
          renderItem={({item}) => (
            <KeyLogItem
              keyLog={item}
              itemClickHandler={() =>
                navigation.navigate(E_NAVIGATION_DESTINATIONS.KEY_LOG_DETAILS, {
                  keyLogItem: item,
                })
              }
            />
          )}
          onRefresh={refetch}
          refreshing={isRefetching}
          onEndReached={loadMore}
          onEndReachedThreshold={0.3}
          ListFooterComponent={isFetchingNextPage ? <LoadingSpinner /> : null}
        />
      )}
      <FilterPanel
        panelActive={panelActive}
        setPanelActive={setPanelActive}
        updateParams={(filters: {status: string}) => {
          setQueryParams({
            ...queryParams,
            status: filters.status,
          });
        }}
      />
    </Container>
  );
}
