import React from 'react';
import isWeb from '../../../utils/isWeb';
import {ScrollView, useWindowDimensions} from 'react-native';

export default function ScreenContent(props: {children: any}) {
  const {width} = useWindowDimensions();
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps="handled"
      style={
        isWeb
          ? {
              backgroundColor: '#ffffff',
              marginHorizontal: 8,
              marginBottom: 16,
              paddingVertical: 32,
              marginTop: 32,
              paddingHorizontal: 32,
              width: width / 3,
            }
          : {
              width: width,
            }
      }>
      {props.children}
    </ScrollView>
  );
}
