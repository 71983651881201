// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback} from 'react';
import {View, FlatList, LogBox} from 'react-native';
import {ActivityIndicator, Card} from 'react-native-paper';
import Text from '../../../components/Text';
import useTailwind from '../../../components/useTailwind';
import {styles} from '../../../styles';
import {NavbarWithAction} from '../../../components';
import Center from '../../../components/Center';
import {useFocusEffect} from '@react-navigation/native';
import {useDispatch, useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import {GetGateCards} from '../gateCardsReducer';
import {E_NAVIGATION_DESTINATIONS} from '../../../@types';
import Container from '../../../components/Container';

const GateCardItem = ({gatecard, itemClickHandler}) => {
  const tailwind = useTailwind();
  
  return (
    <Card style={tailwind('my-2 rounded')} onPress={() => itemClickHandler()}>
      <View style={tailwind('flex-row p-2 justify-between items-center')}>
        <View style={tailwind('flex-1 ml-2 flex-col justify-around')}>
          <Text style={tailwind('text-lg')}>{gatecard.cardNumber}</Text>
          <Text style={tailwind('')}>{gatecard.cardHolderName}</Text>
          <View style={tailwind('flex-1 flex-row mt-8')}>
            <Text style={tailwind('ml-1 text-xs')}>{'hello this'}</Text>
          </View>
        </View>
      </View>
    </Card>
  );
};

const GateCards = ({navigation}) => {
  const {gatecards, loading} = useSelector(
    (state: RootStore) => state.gatecards,
  );
  const dispatch = useDispatch();
  const tailwind = useTailwind();

  const ignoreListsWarnings = () => {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
  };

  useFocusEffect(
    useCallback(() => {
      dispatch(GetGateCards());
      ignoreListsWarnings();
    }, []),
  );

  const MainComponent = () => {
    return (
      <>
        {loading ? (
          <ActivityIndicator
            animating={true}
            color={styles.primaryColor.color}
            style={tailwind('flex-1 items-center content-center')}
          />
        ) : (
          <>
            <FlatList
              data={gatecards}
              style={tailwind('p-2 flex-1 ')}
              showsVerticalScrollIndicator={false}
              ListEmptyComponent={<Center>No gatecards found</Center>}
              ListFooterComponent={<View style={tailwind('h-24')} />}
              renderItem={({item}) => (
                <GateCardItem
                  gatecard={item}
                  itemClickHandler={() =>
                    navigation.navigate(
                      E_NAVIGATION_DESTINATIONS.GATE_CARD_DETAILS,
                      {gateCard: item},
                    )
                  }
                />
              )}
            />
          </>
        )}
      </>
    );
  };

  return (
    <Container>
      <NavbarWithAction
        navigation={navigation}
        hasBackArrow
        title="Gatecards"
        showCurrentUnit
        fullScreen
      />
      <MainComponent />
    </Container>
  );
};

export default GateCards;
