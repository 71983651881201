import React, { useState} from 'react';
import {
  SafeAreaView,
  ScrollView,
  View,
  useWindowDimensions,
} from 'react-native';
import Text from '../../../components/Text';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import useTailwind from '../../../components/useTailwind';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {residentApiWithAuth} from '../../../api/resident';
import {useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import Ionicons from 'react-native-vector-icons/Ionicons';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import {useForm} from 'react-hook-form';
import TextInput from '../../../components/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';

const emailConfirmationValidationSchema = yup.object().shape({
  emailConfirmationCode: yup.string().required('Code is required'),
});

const EmailConfirmation = ({navigation, route}) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const registerValues = useSelector((state: RootStore) => state?.register);
  const {personId} = route.params;
  const {control, handleSubmit} = useForm({
    defaultValues: {
      emailConfirmationCode: '',
    },
    resolver: yupResolver(emailConfirmationValidationSchema)
  });

  const {width} = useWindowDimensions();

  const resendEmailCode = async () => {
    setIsResending(true);
    try {
      const res = await residentApiWithAuth().post(
        `/people/sendEmailCode/${personId}`,
      );
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        showSuccessMessage('The code has been sent to your email');
      }
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setIsResending(false);
    }
  };

  const confirmEmailCode = async ({emailConfirmationCode}: {emailConfirmationCode: string}) => {
    setIsConfirming(true);
    try {
      const res = await residentApiWithAuth().post(
        `/people/confirmEmail/${personId}/${emailConfirmationCode}`,
      );
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        showSuccessMessage('Phone confirmed successfully');
        navigation.navigate('Success');
      }
    } catch (error) {
      showErrorMessage('Invalid code');
    } finally {
      setIsConfirming(false);
    }
  };

  const tailwind = useTailwind();
  
  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          tailwind('flex-1 my-4'),
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <ScrollView
          style={tailwind('mx-2 mb-4')}
          keyboardShouldPersistTaps="handled">
          <RegisterActionBar
            step={5}
            hasSkip={true}
            onSkip={() => navigation.navigate('Success')}
          />
          <View style={tailwind('mx-6 mt-4')}>
            <Heading size="lg">Email Confirmation</Heading>
            {isResending && (
              <View style={tailwind('flex-row mt-8')}>
                <Text style={tailwind('mb-4 ml-2 text-lg')}>
                  Sending code ...
                </Text>
              </View>
            )}

            <View style={tailwind('flex-row mt-8')}>
              <Ionicons
                name="checkmark-circle-outline"
                color="#47B275"
                size={24}
                style={tailwind('mt-1')}
              />
              <Text style={tailwind('mt-1 ml-2 text-lg')}>
                {`Type the code you received via ${registerValues.email}.`}
              </Text>
            </View>

            <View style={tailwind('mt-2 flex-1')}>
              <TextInput
                //@ts-ignore
                control={control}
                name="emailConfirmationCode"
                placeholder="e.g 1234X"
                label="Email code"
              />
              <Button
                onPress={() => resendEmailCode()}
                style={tailwind('mt-6 flex-1')}
                variant="link">
                Resend Code
              </Button>
            </View>
          </View>
        </ScrollView>
        <HStack style={{margin: 24, marginTop: 32}}>
          <RegisterPrevButton />

          <Button
            // isDisabled={!isValid}
            onPress={handleSubmit(confirmEmailCode)}
            isLoading={isConfirming}
            isLoadingText="Confirming code ...."
            style={tailwind('mx-2 flex-1')}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
    </ScreenWrapper>
  );
};

export default EmailConfirmation;
