import React from 'react';
import {View} from 'react-native';
import Text from './Text';
import {colorSwatches} from '../styles';

const DefaultUnitLabel = ({unitNickname, unitName}) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: colorSwatches.primary.color,
      }}>
      <Text
        style={[
          {
            fontSize: 18,
            paddingLeft: 12,
            color: colorSwatches.white.color,
            paddingVertical: 12,
          },
        ]}>
        {` Unit - ${unitNickname ? unitNickname : unitName}`}
      </Text>
    </View>
  );
};

export default DefaultUnitLabel;
