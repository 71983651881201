// @ts-nocheck
import React from 'react';
import {Platform, View, useWindowDimensions} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import useTailwind from './useTailwind';
import {colorSwatches, styles} from '../styles';
import IconButton from '../components/IconButton';
import Text from '../components/Text';
import {Center, HStack} from 'native-base';

type NavbarProps = {
  title: string;
  navigation: {
    goBack: Function;
    canGoBack: () => boolean;
    navigate: Function;
  };
  backHandler?: Function;
};

const Navbar = ({title, navigation, backHandler}: NavbarProps) => {
  const {width} = useWindowDimensions();
  const tailwind = useTailwind();

  const onPress = () => {
    if (backHandler) {
      backHandler();
    } else {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        // navigation.navigate('Dashboard');
        navigation.goBack();
      }
    }
  };

  return (
    <SafeAreaView>
        <View style={styles.navBar}>
          <HStack
            style={{
              flex: 1,
              width: width / 3 + 32,
              alignItems: 'center',
            }}>
            <IconButton
              icon={Platform.OS === 'ios' ? 'chevron-back' : 'arrow-back'}
              size={24}
              style={tailwind('mt-1')}
              onPress={() => onPress()}
              iconColor={colorSwatches.white.color}
            />
            <Text style={styles.title}>{`${title}`}</Text>
          </HStack>
        </View>
    </SafeAreaView>
  );
};

export default Navbar;
