import {applyMiddleware, createStore} from 'redux';
import {thunk} from 'redux-thunk';
import {persistStore, persistReducer} from 'redux-persist';
import rootReducer from '../reducers';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {E_STORAGE_KEYS} from '../../@types';

const persistConfig = {
  key: E_STORAGE_KEYS.REDUX_PERSIST_ROOT,
  storage: AsyncStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(thunk),
);

export const persistor = persistStore(store);

const configureStore = () => {
  return store;
};

const _store = configureStore();

export type RootStore = ReturnType<typeof _store.getState>;
export type AppDispatch = typeof _store.dispatch;

export default configureStore;
