import React from 'react';
import useTailwind from '../../../components/useTailwind';
import {Delivery} from '../../../@types';
import {Card, Menu, } from 'react-native-paper';
import {View} from 'react-native';
import Text from '../../../components/Text';
import {
  deriveDisplayName,
  getCurrentUnitLink,
  permissionToBool,
} from '../../../utils';
import IconButton from '../../../components/IconButton';

export default function DeliveryItem({
  delivery,
  onSelectEdit,
  onSelectDelete,
  itemClickHandler,
}: {
  delivery: Delivery;
  itemClickHandler: () => void;
  onSelectDelete: () => void;
  onSelectEdit: () => void;
}) {
  const [menuVisible, setMenuVisible] = React.useState(false);
  const startDate = new Date(
    delivery?.dateFrame?.startDate,
  ).toLocaleDateString();
  const endDate = new Date(delivery?.dateFrame?.endDate).toLocaleDateString();

  const currentUnitLink = getCurrentUnitLink();

  const tailwind = useTailwind();

  return (
    <Card style={tailwind('rounded my-2')} onPress={() => itemClickHandler()}>
      <View style={tailwind(' flex-row p-2 my-1 justify-between')}>
        <View style={tailwind('flex-1 ml-2 flex-col justify-around')}>
          <View style={tailwind('flex-row')}>
            <Text style={tailwind('text-lg')}>
              {delivery.company ? delivery.company : 'Company not provided'}
            </Text>
          </View>
          <Text>{`Category - ${
            delivery.category ? delivery.category : 'Not provided'
          }`}</Text>
          <Text>{`By ${deriveDisplayName(delivery?.personAuthorizing)}`}</Text>
          <Text>
            {startDate} - {endDate}
          </Text>
        </View>
        {(permissionToBool(currentUnitLink?.deliveryPermission.charAt(1)) ||
          permissionToBool(currentUnitLink?.deliveryPermission.charAt(4))) && (
          <View style={tailwind('mt-3 mr-4')}>
            <Menu
              visible={menuVisible}
              onDismiss={() => setMenuVisible(false)}
              anchor={
                <IconButton
                  icon="ellipsis-vertical"
                  size={24}
                  iconColor={'#718096'}
                  onPress={() => setMenuVisible(true)}
                />
              }>
              {permissionToBool(
                currentUnitLink?.deliveryPermission.charAt(1),
              ) && (
                <Menu.Item
                  onPress={() => {
                    setMenuVisible(false);
                    onSelectEdit();
                  }}
                  title="Edit"
                />
              )}
              {permissionToBool(
                currentUnitLink?.deliveryPermission.charAt(4),
              ) && (
                <Menu.Item
                  onPress={() => {
                    setMenuVisible(false);
                    onSelectDelete();
                  }}
                  title="Delete"
                />
              )}
            </Menu>
          </View>
        )}
      </View>
    </Card>
  );
}
