import React from 'react';
import Container from '../../../components/Container';
import Content from '../../../navigation/Content';
import { Navbar } from '../../../components';
import { useNavigation } from '@react-navigation/native';
import YoutubePlayer from 'react-native-youtube-iframe';
import { ScrollView, View, useWindowDimensions } from 'react-native';

const TrainingVideo = () => {
    const navigation = useNavigation();
    const {width} = useWindowDimensions();
    return (
      <Container>
        <Content>
          <Navbar title="Training Video" navigation={navigation} />
          <ScrollView 
          style={{
            flex:1,
            height: '100%'
          }}
          contentContainerStyle={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >
          <YoutubePlayer
          height={300}
          width={width -20}
          videoId='1G2YsUWAsnA'
          />
          </ScrollView>
        </Content>
      </Container>
    );
};
export default TrainingVideo;