import React from 'react';
import {
  SafeAreaView,
  ScrollView,
  View,
  useWindowDimensions,
} from 'react-native';
import Text from '../../../components/Text';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import Input from '../../../components/Input';
import useTailwind from '../../../components/useTailwind';
import {yupResolver} from '@hookform/resolvers/yup' 
import {useDispatch, useSelector} from 'react-redux';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import {RootState} from '../../../redux/reducers';
import {RegistrationData} from '../types';
import {setRegisterData} from '../registerReducer';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import { useForm } from 'react-hook-form';
import TextInput from '../../../components/TextInput';

const firstNameValidationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
});

const Name = ({navigation}) => {
  const dispatch = useDispatch();
  const {firstName, lastName, dlNumber, addressLine1, expiration, dateOfBirth} =
    useSelector((state: RootState) => state?.register);

  const {control, handleSubmit} = useForm({
    defaultValues:{
      firstName,
      lastName,
      //@ts-ignore
      dlNumber,
      addressLine1
    },
    resolver: yupResolver(firstNameValidationSchema)
  })  
  const {width} = useWindowDimensions();

  const handleDetailsSubmit = (values: {
    firstName: string;
    lastName: string;
    addressLine1: string;
    dlNumber: string;
  }) => {
    const registrationData: RegistrationData = {
      firstName: values.firstName,
      lastName: values.lastName,
      addressLine1: values.addressLine1,
      dlNumber: values.dlNumber,
    };
    // @ts-ignore
    dispatch(setRegisterData(registrationData));
    navigation.navigate('Photo Capture');
  };

  const tailwind = useTailwind();
  
  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          tailwind('flex-1 my-4'),
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <RegisterActionBar step={6} />
        <ScrollView keyboardShouldPersistTaps="handled">
          <View style={tailwind('mx-6 mt-4')}>
            <Heading size="lg">Personal details </Heading>
            <Text style={tailwind('mt-8 text-lg mb-4')}>
              Please look over all information to make sure it is accurate.
            </Text>
            <TextInput
              //@ts-ignore
              control={control}
              name="firstName"
              label="First name *"
            />
            <TextInput
              //@ts-ignore
              control={control}
              name="lastName"
              label="Last name *"
            />
            <TextInput
              //@ts-ignore
              control={control}
              name="dlNumber"
              label="Driver's license number"
            />
            <View style={tailwind('flex-1')}>
              <Text style={[tailwind('my-2')]}>Birth date </Text>
              <Input
                style={tailwind('mt-2')}
                value={dateOfBirth}
              />
            </View>
            <View style={tailwind('mt-6 flex-1 mb-4')}>
              <Text style={[tailwind('my-2')]}>
                Driver's license expiry date
              </Text>
              <Input
                style={tailwind('mt-2')}
                value={expiration}
              />
            </View>
            <TextInput
              //@ts-ignore
              control={control}
              name="addressLine1"
              label="Address"
            />
          </View>
        </ScrollView>
        <HStack style={{margin: 24, marginTop: 32}}>
          <RegisterPrevButton />
          <Button
            // isDisabled={!isValid}
            onPress={handleSubmit(handleDetailsSubmit)}
            style={tailwind('flex-1')}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
    </ScreenWrapper>
  );
};

export default Name;
