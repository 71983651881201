// @ts-nocheck
import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import useTailwind from './useTailwind';
import {ThemedSwipeablePanel} from '.';
import {styles} from '../styles';
import Text from '../components/Text';

const OptionsSheet = ({
  isPanelActive,
  setIsActive,
  title,
  labelOne,
  handlerOne,
  labelTwo,
  handlerTwo,
  labelThree,
  handlerThree,
}: {
  isPanelActive: boolean;
  setIsActive: any;
  title: string;
  labelOne: string;
  handlerOne: any;
  labelTwo: string;
  handlerTwo: any;
  labelThree?: string;
  handlerThree?: any;
}) => {
  const tailwind = useTailwind();

  return (
    <ThemedSwipeablePanel
      onlySmall
      fullWidth
      isActive={isPanelActive}
      onClose={() => setIsActive(false)}
      showCloseButton
      closeOnTouchOutside={true}
      closeIconStyle={tailwind('bg-gray-500')}
      closeRootStyle={tailwind('bg-white')}
      noBar
      style={{height: 680}}>
      <View style={tailwind('mt-3 ml-3')}>
        <Text style={styles.sheetTitle}>{title}</Text>
        <View style={tailwind('mt-3')}>
          <TouchableOpacity
            onPress={() => {
              setIsActive(false);
              handlerOne();
            }}>
            <Text style={styles.sheetLabel}>{labelOne}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setIsActive(false);
              handlerTwo();
            }}>
            <Text style={styles.sheetLabel}>{labelTwo}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setIsActive(false);
              handlerThree();
            }}>
            <Text style={styles.sheetLabel}>{labelThree}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ThemedSwipeablePanel>
  );
};

export default OptionsSheet;
