import {BASE_URL} from '../../api/constants';
import axios from 'axios';
import {store} from '../../redux/store';
import {residentApi, residentApiWithAuth} from '../../api/resident';
import {showErrorMessage} from '../../service/flashMessage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {E_STORAGE_KEYS} from '../../@types';
import { UserCreds } from '../../screens/more/types';

export async function signIn(username, password) {
  const {deviceInfo} = store.getState();
  const url = `${BASE_URL}/people/login`;
  const data = {username: username, password: password};
  try {
    let res = await axios.post(url, data, {
      headers: {
        common: deviceInfo,
      },
    });
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    const message = error?.response?.data?.errors[0];
    throw new Error(message);
  }
}

/**
 * TODO: Add pushy token generation logic here
 */
export async function registerDevice(authToken: string, deviceToken: string) {
  try {
    await residentApiWithAuth().get(`/people/registerPushyId/${deviceToken}`);
  } catch (error) {
    showErrorMessage(error.message);
  }
}

export async function deregisterDevice() {
  try {
    const savedTokenString = await AsyncStorage.getItem(
      E_STORAGE_KEYS.PUSHY_DEVICE_TOKEN,
    );
    const deviceTokenObj = savedTokenString
      ? JSON.parse(savedTokenString)
      : undefined;

    if (deviceTokenObj !== undefined) {
      const res = await residentApiWithAuth().get(
        `/people/deregisterPushyId/${deviceTokenObj.deviceToken}`,
      );
      await AsyncStorage.removeItem(E_STORAGE_KEYS.PUSHY_DEVICE_TOKEN);
    } else {
      Promise.resolve();
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function sendAck(ackData) {
  try {
    let res = await residentApiWithAuth().post(
      'alertDetail/person/updateAction',
      ackData,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    console.log(JSON.stringify(error));
    throw new Error(error);
  }
}

export async function postDriverLicense(driversLicense) {
  try {
    let res = await residentApi().post('/idAnalyzer', {imageBase64: driversLicense});
    const result = res.data;

    if (result.errors) {
      throw new Error(result.errors[0]);
    } else if (result.data.ERROR) {
      throw new Error(result.data.ERROR);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function checkUser(username) {
  try {
    let res = await residentApiWithAuth().get(`/checkuser/${username}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export async function resetPassword(username) {
  try {
    let res = await residentApi().post('/people/passwordemail', {
      username,
    });
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    const message = error?.response?.data?.data?.errors[0] || error.message;
    throw new Error(message);
  }
};

export async function updateUserAccount(userId, userData) {
  try {
    let res = await residentApiWithAuth().post(`/people/${userId}`, userData);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error('updateUserAccount' + error.message);
  }
};

export async function deleteAccount(userCreds: UserCreds) {
  try {
    let res = await residentApiWithAuth().delete(`/people/deleteProfile`, {data: {...userCreds}});
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    const errorMsg = error.response.data.errors[0]
    throw new Error(errorMsg);
  }
}

export async function checkEmail(email: string) {
  try {
    let res = await residentApi().get(`/people/checkEmail/${email}`);
    const result = res.data;

    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (err) {
    const errorMsg = err.response.data.errors[0];
    throw new Error(errorMsg);
  }
}

export async function checkPhone(phone: string) {
  try {
    let res = await residentApi().get(`/people/checkPhone/${phone}`);
    const result = res.data;

    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (err) {
    const errorMsg = err.response.data.errors[0];
    throw new Error(errorMsg);
  }
}

