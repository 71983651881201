// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useCallback} from 'react';
import {View, FlatList, LogBox, Alert} from 'react-native';
import {Divider, Menu, ActivityIndicator, Card} from 'react-native-paper';
import useTailwind from '../../../components/useTailwind';
import {styles} from '../../../styles';
import {showErrorMessage} from '../../../service/flashMessage';
import {MySearchbar, NavbarWithAction} from '../../../components';
import {deleteVehicle} from '../../../api/vehicles';
import {useFocusEffect} from '@react-navigation/native';
import {useDispatch, useSelector} from 'react-redux';
import Center from '../../../components/Center';
import {RootStore} from '../../../redux/store';
import {GetVehicles} from '../vehicleReducer';
import {permissionToBool} from '../../../utils';
import styled from 'styled-components/native';
import IconButton from '../../../components/IconButton';
import Text from '../../../components/Text';
import {E_NAVIGATION_DESTINATIONS} from '../../../@types';
import Container from '../../../components/Container';

const CardImage = styled.Image`
  width: 116px;
  height: 100%;
  border-radius: 6px;
`;

const VehicleItem = ({
  vehicle,
  onSelectDelete,
  onSelectEdit,
  itemClickHandler,
  currentLink,
}) => {
  const tailwind = useTailwind();
  const [menuVisible, setMenuVisible] = useState(false);
  const imageUrl = vehicle.vehicleImage
    ? `data:image/jpeg;base64,${vehicle.vehicleImage}`
    : 'https://ford.prodealerwebsites.com.au/specials/default.png';

  return (
    <Card style={tailwind('my-2 rounded')} onPress={() => itemClickHandler()}>
      <View style={tailwind('flex-row p-2 justify-between items-center')}>
        <CardImage
          source={{
            uri: imageUrl,
          }}
        />
        <View style={tailwind('flex-1 ml-2 flex-col justify-around')}>
          <Text style={tailwind('text-lg')}>{vehicle.vehicleOwner}</Text>
          <Text style={tailwind('')}>{vehicle.licensePlate}</Text>
          <View style={tailwind('flex-1 flex-row mt-8')}>
            <Text style={tailwind('ml-1 text-xs')}>
              {`${new Date(
                vehicle?.dateFrame?.startDate,
              ).toLocaleDateString()} - ${new Date(
                vehicle?.dateFrame?.endDate,
              ).toLocaleDateString()}`}
            </Text>
          </View>
        </View>
        {(permissionToBool(currentLink?.vehiclePermission.charAt(1)) ||
          permissionToBool(currentLink?.vehiclePermission.charAt(4))) && (
          <View style={tailwind('mt-4 mr-4')}>
            <Menu
              visible={menuVisible}
              onDismiss={() => setMenuVisible(false)}
              anchor={
                <IconButton
                  icon="ellipsis-vertical"
                  iconColor={'#718096'}
                  size={24}
                  onPress={() => setMenuVisible(true)}
                />
              }>
              {permissionToBool(currentLink?.vehiclePermission.charAt(1)) && (
                <>
                  <Menu.Item
                    onPress={() => {
                      setMenuVisible(false);
                      onSelectEdit();
                    }}
                    title="Edit"
                  />
                  <Divider />
                </>
              )}
              {permissionToBool(currentLink?.vehiclePermission.charAt(4)) && (
                <Menu.Item
                  onPress={() => {
                    setMenuVisible(false);
                    onSelectDelete();
                  }}
                  title="Delete"
                />
              )}
            </Menu>
          </View>
        )}
      </View>
    </Card>
  );
};

const Vehicles = ({navigation}) => {
  const {vehicles, loading} = useSelector((state: RootStore) => state.vehicles);
  const [searchTerm, setSearchTerm] = useState('');
  const [isNavbarShown, setIsNavbarShown] = useState(true);
  const dispatch = useDispatch();
  const tailwind = useTailwind();

  const currentLink = useSelector(
    (state: RootStore) => state.units.unitLinks,
  ).filter(link => link.isDefault === true)[0];

  const deleteVehicleWithId = async vehicleId => {
    try {
      await deleteVehicle(vehicleId);
      dispatch(GetVehicles(''));
    } catch (error) {
      showErrorMessage(error.toString());
    }
  };

  const ignoreListsWarnings = () => {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
  };

  useFocusEffect(
    useCallback(() => {
      dispatch(GetVehicles(searchTerm));
      ignoreListsWarnings();
    }, [searchTerm]),
  );

  const showDeleteUnitPrompt = (vehicleId: string) => {
    // setUserMenuVisible(false);
    Alert.alert(
      '',
      'Do you really want to delete vehicle ?',
      [
        {
          text: 'NO',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: 'YES',
          onPress: () => deleteVehicleWithId(vehicleId),
        },
      ],
      {
        cancelable: true,
      },
    );
  };

  const MainComponent = () => {
    return (
      <>
        {loading ? (
          <ActivityIndicator
            animating={true}
            color={styles.primaryColor.color}
            style={tailwind('flex-1 items-center content-center')}
          />
        ) : (
          <>
            <FlatList
              data={vehicles}
              style={tailwind('p-2 flex-1 ')}
              showsVerticalScrollIndicator={false}
              ListEmptyComponent={<Center>No vehicles found</Center>}
              ListFooterComponent={<View style={tailwind('h-24')} />}
              renderItem={({item}) => (
                <VehicleItem
                  vehicle={item}
                  onSelectDelete={() => showDeleteUnitPrompt(item.id)}
                  onSelectEdit={() =>
                    navigation.navigate(
                      E_NAVIGATION_DESTINATIONS.VEHICLE_EDIT,
                      {vehicle: item},
                    )
                  }
                  itemClickHandler={() =>
                    navigation.navigate('Vehicle Detail', {vehicleId: item.id})
                  }
                  currentLink={currentLink}
                />
              )}
            />
          </>
        )}
      </>
    );
  };

  return (
    <Container>
      {isNavbarShown ? (
        <NavbarWithAction
          navigation={navigation}
          hasBackArrow
          hasSearch={vehicles.length > 0}
          title="Vehicles"
          showCurrentUnit
          onClickSearch={() => setIsNavbarShown(false)}
          hasAdd={permissionToBool(currentLink?.vehiclePermission?.charAt(3))}
          onClickAdd={() =>
            navigation.navigate(E_NAVIGATION_DESTINATIONS.VEHICLE_ADD, {
              vehicle: null,
            })
          }
          fullScreen
        />
      ) : (
        <MySearchbar
          placeholder="Search by license plate"
          setValue={(val: string) => {
            setSearchTerm(val);
          }}
          resetSearch={() => {
            setSearchTerm('');
            setIsNavbarShown(true);
          }}
        />
      )}
      <MainComponent />
    </Container>
  );
};

export default Vehicles;
