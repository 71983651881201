import React, {useState} from 'react';
import {ScrollView, View} from 'react-native';
import Text from '../../../components/Text';
import {ActivityIndicator, Card, Divider} from 'react-native-paper';
import useTailwind from '../../../components/useTailwind';
import {styles} from '../../../styles';
import {Navbar} from '../../../components';
import {useEffect} from 'react';
import {showErrorMessage} from '../../../service/flashMessage';
import {residentApiWithAuth} from '../../../api/resident';
import {Violation} from '../types';
import Container from '../../../components/Container';

const ViolationDetail = ({navigation, route}) => {
  const {violationId} = route.params;
  const [isLoading, setIsLoading] = useState(false);
  const [violation, setViolation] = useState<Violation>({});

  const tailwind = useTailwind();

  const fetchViolation = async (id: string) => {
    try {
      let res = await residentApiWithAuth().get(`/violations/${id}`);
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        setViolation(result.data);
        setIsLoading(false);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchViolation(violationId);
  }, [violationId]);

  return (
    <Container style={[tailwind('flex-1')]}>
      <Navbar navigation={navigation} title="Violation details" />
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          <ScrollView
            style={tailwind('mx-2 py-4')}
            showsVerticalScrollIndicator={false}>
            <Card style={[tailwind('p-2 mb-8')]}>
              <View style={tailwind('flex-1 flex-row justify-between')} />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Violation Category</Text>
                <Text style={styles.detailsText}>
                  {violation.violationCategory}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Specific Violation</Text>
                <Text style={styles.detailsText}>
                  {violation.specificViolation}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Severity Of Violation</Text>
                <Text style={styles.detailsText}>
                  {violation.severityOfViolation}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row my-3')}>
                <Text style={styles.textLabel}>Violation Description</Text>
                <Text style={styles.detailsText}>
                  {violation.violationDescription}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Violation Source</Text>
                <Text style={styles.detailsText}>
                  {violation.violationSource}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>License Plate</Text>
                <Text style={styles.detailsText}>{violation.licensePlate}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>License State</Text>
                <Text style={styles.detailsText}>{violation.licenseState}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Date Reported</Text>
                <Text style={styles.detailsText}>{violation.dateReported}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Time Reported</Text>
                <Text style={styles.detailsText}>{violation.timeReported}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Action Taken</Text>
                <Text style={styles.detailsText}>{violation.actionTaken}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Next Status</Text>
                <Text style={styles.detailsText}>{violation.nextStatus}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Date Corrected</Text>
                <Text style={styles.detailsText}>
                  {violation.dateCorrected}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Time Corrected</Text>
                <Text style={styles.detailsText}>
                  {violation.timeCorrected}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Reported By</Text>
                <Text style={styles.detailsText}>{violation.reportedBy}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Location of violation</Text>
                <Text style={styles.detailsText}>
                  {violation.locationOfViolation}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Status Of Violation</Text>
                <Text style={styles.detailsText}>
                  {violation.statusOfViolation}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Violation Fee</Text>
                <Text style={styles.detailsText}>{violation.violationFee}</Text>
              </View>
            </Card>
          </ScrollView>
        </>
      )}
    </Container>
  );
};

export default ViolationDetail;
