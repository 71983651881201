// @ts-nocheck
import React, {useState} from 'react';
import {ScrollView, View, TouchableOpacity, Text} from 'react-native';
import useTailwind from '../../../components/useTailwind';
import DateTimeInput from '../../../components/DateTimeInput';
import {Navbar, SubmitButton} from '../../../components';
import {styles} from '../../../styles';
import dataFrameInitialState from '../utils';
import {createDateFrame} from '../../../api/dataframe';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import Icon from 'react-native-vector-icons/Feather';
import Input from '../../../components/Input';
import VStack from '../../../components/VStack';
import TextArea from '../../../components/TextArea';
import Checkbox from '../../../components/Checkbox';
import Select from '../../../components/Select';

const CreateDateFrame = ({navigation, route}) => {
  const unitId = route.params?.unitId;
  const [dateFrameState, setDateFrameState] = useState({
    ...dataFrameInitialState,
    unitId,
  });
  const [numberOfDays, setNumberOfDays] = useState(1);

  const tailwind = useTailwind();

  const handleStartDateConfirm = date => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + parseInt(numberOfDays, 10));

    setDateFrameState({...dateFrameState, startDate: date, endDate: newDate});
  };

  const handleEndDateConfirm = date => {
    const diffTime = new Date(date) - new Date(dateFrameState.startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setNumberOfDays(diffDays);
    setDateFrameState({...dateFrameState, endDate: date});
  };

  const collectUserInput = async () => {
    let dateFrame = dateFrameState;
    if (!dateFrameState.shortDescription) {
      dateFrame.shortDescription = `${new Date(
        dateFrame.startDate,
      ).toDateString()}- ${new Date(dateFrame.endDate).toDateString()}`;
    }
    try {
      await createDateFrame(dateFrame);
      showSuccessMessage('Date frame created');
      navigation.goBack();
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  return (
    <View style={[tailwind('flex-1')]}>
      <Navbar navigation={navigation} title="Create date frame" />
      <ScrollView
        style={tailwind('px-2 py-4')}
        showsVerticalScrollIndicator={false}>
        <>
          <>
            <Text style={tailwind('mt-4 mb-2 flex-1')}>Date frame type</Text>
            <Select
              placeholder="Select dateframe type"
              minWidth={300}
              selectedValue={dateFrameState.type}
              onValueChange={val =>
                setDateFrameState({...dateFrameState, type: val})
              }>
              <Select.Item label="Reservation" value="Reservation" />
              <Select.Item label="Lease" value="Lease" />
              <Select.Item label="Visitor" value="Visitor" />
            </Select>
          </>
          <DateTimeInput
            label="From"
            value={new Date(dateFrameState.startDate)}
            onConfirm={date => {
              handleStartDateConfirm(date);
            }}
            mode='date'
          />
          <VStack>
            <Text style={[styles.textInputLabel, tailwind('mt-2')]}>Days</Text>
            <Input
              placeholder="Enter number of days"
              value={numberOfDays.toString()}
              keyboardType="number-pad"
              onChangeText={text => {
                setNumberOfDays(parseInt(text === '' ? 0 : text, 10));
                const date = new Date(dateFrameState.startDate);
                date.setDate(
                  date.getDate() + parseInt(text === '' ? 0 : text, 10),
                );
                setDateFrameState({...dateFrameState, endDate: date});
              }}
              minWidth={200}
              InputRightElement={
                <TouchableOpacity
                  style={tailwind('px-4')}
                  onPress={() => {
                    let newDays = numberOfDays + 1;
                    setNumberOfDays(newDays);
                    const date = new Date(dateFrameState.startDate);
                    date.setDate(date.getDate() + newDays - 1);
                    setDateFrameState({...dateFrameState, endDate: date});
                  }}>
                  <Icon name="plus" size={24} color="#007bff" />
                </TouchableOpacity>
              }
              InputLeftElement={
                <TouchableOpacity
                  disabled={numberOfDays === 1}
                  style={tailwind('px-4 flex-1')}
                  onPress={() => {
                    let newDays = numberOfDays - 1 >= 1 ? numberOfDays - 1 : 1;
                    setNumberOfDays(newDays);
                    const date = new Date(dateFrameState.startDate);
                    date.setDate(date.getDate() + newDays - 1);
                    setDateFrameState({...dateFrameState, endDate: date});
                  }}>
                  <Icon name="minus" size={24} color="#007bff" />
                </TouchableOpacity>
              }
            />
          </VStack>
          <DateTimeInput
            label="To"
            value={new Date(dateFrameState.endDate)}
            onConfirm={date => {
              handleEndDateConfirm(date);
            }}
            mode='date'
          />
          <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
            <Text style={styles.checkBoxLabel}>Allow overlap ?</Text>
            <Checkbox
              disabled={false}
              tintColors={{
                true: '#007bff',
                false: '#A0AEC0',
              }}
              value={dateFrameState.overlapAllowed}
              onChange={value =>
                setDateFrameState({
                  ...dateFrameState,
                  overlapAllowed: value,
                })
              }
              aria-label="Allowed to overlap"
              style={tailwind('mt-1')}
            />
          </View>
          <Text style={styles.textInputLabel}>Short description</Text>
          <Input
            placeholder="Enter short description"
            value={dateFrameState.shortDescription}
            onChangeText={text =>
              setDateFrameState({...dateFrameState, shortDescription: text})
            }
          />
          <>
            <Text style={styles.textInputLabel}>Notes</Text>
            <TextArea
              placeholder="Enter notes"
              value={dateFrameState.notes}
              style={styles.multiLinetextInput}
              multiline
              onChangeText={text =>
                setDateFrameState({...dateFrameState, notes: text})
              }
            />
          </>
        </>
      </ScrollView>
      <SubmitButton onPress={collectUserInput} title={'Save'} />
    </View>
  );
};

export default CreateDateFrame;
