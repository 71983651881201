import React from 'react';
import {ScrollView, View} from 'react-native';
import Text from '../../../components/Text';
import useTailwind from '../../../components/useTailwind';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';

const Welcome = ({navigation}) => {
  const tailwind = useTailwind();
  
  return (
    <View style={tailwind('flex-1 mb-4')}>
      <ScrollView style={tailwind('mx-2 mb-4 mt-12')}>
        <View style={tailwind('mx-6 mt-4')}>
          <Heading>Welcome to Asterix Community App. </Heading>
          <Text style={tailwind('mt-8 text-lg')}>
            Click Create account to set up a new account. If you already have an
            account, click Sign in
          </Text>
        </View>
      </ScrollView>
      <HStack>
        <Button
          onPress={() => navigation.navigate('Account Setup Info')}
          style={tailwind('mx-2 flex-1')}>
          Create account
        </Button>
        <Button
          style={tailwind('mx-2 flex-1')}
          onPress={() => navigation.navigate('Sign In')}
          variant="outline">
          Sign in
        </Button>
      </HStack>
    </View>
  );
};

export default Welcome;
