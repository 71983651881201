import {IInputProps, Input as NativeBaseInput} from 'native-base';
import React from 'react';
import useTailwind from './useTailwind';
import { colorSwatches } from '../styles';

const Input = (props: IInputProps) => {
  const tailwind = useTailwind();
  
  return (
    <NativeBaseInput {...props} style={[props.style, tailwind('text-lg')]} 
    _disabled={{
       backgroundColor: colorSwatches.gray.color 
    }}
    _readOnly={{
      backgroundColor: colorSwatches.gray.color 
    }}
    />
  );
};

export default Input;
