// @ts-nocheck
import React, {useState, useEffect} from 'react';
import {View, ScrollView} from 'react-native';
import Text from '../../../components/Text';
import {Navbar} from '../../../components';
import Select from '../../../components/Select';
import useTailwind from '../../../components/useTailwind';
import {styles} from '../../../styles';
import {useSelector} from 'react-redux';
import {fields, initialViolationState} from '../utils';
import DateTimeInput from '../../../components/DateTimeInput';
import Icon from 'react-native-vector-icons/Feather';
import Input from '../../../components/Input';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {createViolation, updateViolation} from '../../../api/violation';
import {RootStore} from '../../../redux/store';
import Button from '../../../components/Button';
import FormContainer from '../../../components/FormContainer';
import licenseStates from '../../../utils/licenseStates';
import Container from '../../../components/Container';

const CreateViolation = ({route, navigation}) => {
  const {violation} = route.params;
  const isInEditMode = violation != null;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userId = useSelector((state: RootStore) => state.auth.user.id);
  const unitId = useSelector(
    (state: RootStore) => state.units.unitLinks,
  ).filter(link => link.isDefault === true)[0].unit.id;
  const [violationState, setViolationState] = useState({
    ...initialViolationState,
    createdLogon: userId,
    lastLogon: userId,
    unitId: unitId,
  });
  const [dateReportedShown, setDateReportedShown] = useState(false);
  const [dateCorrectedShown, setDateCorrectedShown] = useState(false);
  const [dateCorrectedByShown, setDateCorrectedByShown] = useState(false);

  const tailwind = useTailwind();

  const dateReportedChangeHandler = selectedDate => {
    setViolationState({...violationState, dateReported: selectedDate});
  };

  const dateCorrectedChangeHandler = selectedDate => {
    setDateCorrectedShown(false);
    setViolationState({...violationState, dateCorrected: selectedDate});
  };

  const dateCorrectedByChangeHandler = selectedDate => {
    setDateCorrectedByShown(false);
    setViolationState({...violationState, dateToBeCorrectedBy: selectedDate});
  };

  useEffect(() => {
    if (isInEditMode) {
      setViolationState({...violationState, ...violation});
      navigation.setOptions({
        title: 'Edit violation',
        headerBackImage: () => <Icon name="x" size={24} />,
      });
    }
  }, []);

  const collectUserInput = async violationData => {
    setIsSubmitting(true);
    try {
      isInEditMode
        ? await updateViolation(violationData, violationData.id)
        : await createViolation(violationData);
      showSuccessMessage('Vehicle saved successfully');
      navigation.goBack();
    } catch (e) {
      showErrorMessage(e.message);
    }
    setIsSubmitting(false);
  };

  return (
    <Container>
      <Navbar navigation={navigation} title="Add Violation" />
      <FormContainer>
        <ScrollView
          style={tailwind('mx-2 my-4')}
          showsVerticalScrollIndicator={false}>
          <>
            {fields.map(field => {
              if (field.type === 'textInput') {
                return (
                  <View key={field.fieldName}>
                    <Text style={styles.textInputLabel}>{field.fieldName}</Text>
                    <Input
                      placeholder={`Enter ${field.fieldName}`}
                      value={violationState[field.state]}
                      onChangeText={text =>
                        setViolationState({
                          ...violationState,
                          [field.state]: text,
                        })
                      }
                    />
                  </View>
                );
              } else if (field.type === 'select') {
                return (
                  <View key={field.fieldName}>
                    <Text style={styles.textInputLabel}>{field.fieldName}</Text>
                    <Select
                      minWidth={200}
                      selectedValue={violationState[field.state]}
                      placeholder={`Select ${field.fieldName}`}
                      onValueChange={val =>
                        setViolationState({
                          ...violationState,
                          [field.state]: val,
                        })
                      }>
                      {licenseStates.map(state => (
                        <Select.Item key={state} label={state} value={state} />
                      ))}
                    </Select>
                  </View>
                );
              }
            })}
          </>
          <DateTimeInput
            label="Date reported"
            value={new Date(violationState.dateReported)}
            onConfirm={date => {
              dateReportedChangeHandler(date);
            }}
            mode="date"
          />
          <DateTimeInput
            label="Date corrected"
            value={new Date(violationState.dateCorrected)}
            onConfirm={date => {
              dateReportedChangeHandler(date);
            }}
            mode="date"
          />
          <DateTimeInput
            label="Date corrected"
            value={new Date(violationState.dateToBeCorrectedBy)}
            onConfirm={date => {
              dateCorrectedByChangeHandler(date);
            }}
            mode="date"
          />
        </ScrollView>
        <Button
          onPress={() => {
            collectUserInput(violationState);
          }}
          style={tailwind('mx-2 mb-20')}
          py="4"
          isLoading={isSubmitting}
          isLoadingText="Submitting ...">
          Save
        </Button>
      </FormContainer>
    </Container>
  );
};

export default CreateViolation;
