import React, { useCallback } from 'react';
import {
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import Text from '../../../components/Text';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import useTailwind from '../../../components/useTailwind';
import Icon from 'react-native-vector-icons/Feather';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import {useDispatch} from 'react-redux';
import {RegistrationData} from '../types';
import {resetRegisterData, setRegisterData} from '../registerReducer';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import TextInput from '../../../components/TextInput';
import { checkEmail, checkUser } from '../api';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { useFocusEffect } from '@react-navigation/native';

const emailValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter a valid email'),
});

const EmailCheck = ({navigation}) => {
  const dispatch = useDispatch();
  const {width} = useWindowDimensions();
  const {control, handleSubmit} = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(emailValidationSchema),
  });

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const closeDialog =()=> setDialogOpen(false);
  const [usernameMatchesEmail, setUsernameMatchesEmail] = React.useState(true);

  const [loading, setLoading] = React.useState(false);

  const handleEmailSubmit = async(values: {email: string}) => {
    setLoading(true);
    const emailExists =  await checkEmail(values.email);

    setLoading(false);
    if (emailExists.emailExists) {
      setUsernameMatchesEmail(emailExists.asUserName);
      setDialogOpen(true);
      return;
    }

    const registrationData: RegistrationData = {
      email: values.email,
    };
    //@ts-ignore
    dispatch(setRegisterData(registrationData));
    navigation.navigate('Phone');
  };
  
  const tailwind = useTailwind();

  useFocusEffect(
    useCallback(() => {
      //@ts-ignore
      dispatch(resetRegisterData())
    }, []),
  );


  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          tailwind('flex-1 my-4'),
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <RegisterActionBar step={1} />
        <ScrollView
          style={[tailwind('mx-2 mb-4')]}
          keyboardShouldPersistTaps="handled">
          <View style={tailwind('mx-6 mt-4')}>
            <Heading size="lg">Email</Heading>
            <Text style={tailwind('mt-4 text-lg')}>
              Type in a valid email address. We will send you a code to confirm
              your email.
            </Text>
            <Text style={tailwind('mt-4 text-lg')}>
              The code is case sensitive.
            </Text>
            <TextInput
              //@ts-ignore
              control={control}
              name="email"
              placeholder="e.g johndoe@xyz.com"
              label="Email"
              keyboardType="email-address"
              autoCapitalize="none"
              InputRightElement={
                <TouchableOpacity style={tailwind('px-2')}>
                  <Icon name={'mail'} size={24} color="grey" />
                </TouchableOpacity>
              }
              autoFocus
            />
          </View>
        </ScrollView>
        <HStack style={{margin: 24, marginTop: 32}}>
          <RegisterPrevButton />
          <Button
          isLoading={loading}
          isLoadingText='Checking...'
            onPress={handleSubmit(handleEmailSubmit)}
            style={tailwind('mx-2 flex-1')}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
      <ConfirmationDialog
        isOpen={dialogOpen}
        onClose={closeDialog}
        onNoPressed={closeDialog}
        onYesPressed={() =>
          navigation.navigate('Sign In', {email: control._formValues.email})
        }
        title="Email already exists"
        description={usernameMatchesEmail ? 'A profile username already exists for this email, please login to continue' : 'Email is already in use, please select an option:'}
        negativeLabel="CANCEL"
        positiveLabel="LOGIN "
        centerButton={!usernameMatchesEmail}
        centerButtonLabel='EMAIL USERNAME'
        onCenterButtonPressed={() => console.log("Send username to this email")}
      />
    </ScreenWrapper>
  );
};

export default EmailCheck;
