import React from 'react';
import AlertDialog from './AlertDialog';
import useTailwind from './useTailwind';
import {View} from 'react-native';
import LoadingSpinner from './LoadingSpinner';

export default function AsterixDialog({isSwitching, message}) {
  const cancelRef = React.useRef(null);
  const tailwind = useTailwind();

  return (
    <AlertDialog 
    leastDestructiveRef={cancelRef} 
    isOpen={isSwitching}
    >
      <AlertDialog.Content>
        <AlertDialog.Header>Please wait</AlertDialog.Header>
        <View style={tailwind('flex-row p-2')}>
          <LoadingSpinner />
          <AlertDialog.Body>{message}</AlertDialog.Body>
        </View>
      </AlertDialog.Content>
    </AlertDialog>
  );
}
