import React, {useCallback, useEffect} from 'react';
import {useState} from 'react';
import {FlatList, Platform, Pressable, View} from 'react-native';
import Text from '../../../components/Text';
import useTailwind from '../../../components/useTailwind';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {
  EmptyList,
  LoadingSpinner,
  NavbarWithAction,
  RequireApproval,
} from '../../../components';
import {
  showErrorMessage,
  showInformationMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {
  createDateFrame,
  dateFrameRequestAccess,
  deleteUnitLinkDateFrame,
  fetchDateFrameByPerson,
  fetchDateFramesByUnit,
  updatePersonDateFrames,
} from '../../../api/dataframe';
import {
  convertDateFrameToDays,
  markCalendarDays,
} from '../../../utils/dateFrames';
import {ActivityIndicator, Card} from 'react-native-paper';
import {colorSwatches} from '../../../styles';
import {useFocusEffect} from '@react-navigation/native';
import CreateDateFramePanel from '../../../components/CreateDateFramePanel';
import {CalendarDateFrame, DateFrame} from '../../../@types/DateFrame';
import {toLocalDateTime} from '../../../utils';
import {SYSTEM_FOREVER_DATEFRAME_ID} from '../../../constants';
import {showAlert} from '../../../service';
import Button from '../../../components/Button';
import Ionicon from 'react-native-vector-icons/Ionicons';
import HStack from '../../../components/HStack';
import Menu from '../../../components/Menu';
import Modal from '../../../components/Modal';
import Select from '../../../components/Select';
import VStack from '../../../components/VStack';
import Container from '../../../components/Container';
import FormContainer from '../../../components/FormContainer';
import AuthorizeButtons from '../components/AuthorizeButtons';
import {buildTodayAlertArgs, buildTommorowAlertArgs} from '../utils';
import {RequestAccess} from '../types';
import {store} from '../../../redux/store';
import {GetPeople} from '../../People/peopleReducer';
import Legend from '../components/Legend';
import isWeb from '../../../utils/isWeb';
import CustomCalendar from '../components/CustomCalendar';

const UnitCalendar = ({navigation, route}) => {
  const {
    unitId,
    unitPeopleLinkId,
    unitName,
    isLoggedInUser,
    personName,
    allowedToday,
    allowedTommorow,
  } = route.params;
  const TITLE = `${
    isLoggedInUser ? 'Your' : personName
  } access for unit ${unitName}`;
  const [dateFrames, setDateFrames] = useState<Array<CalendarDateFrame>>([]);
  const [markedDates, setmarkedDates] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDateFrame, setSelectedDateFrame] = useState('');
  const [panelActive, setPanelActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasForeverDateframe, setHasForeverDateFrame] = useState(true);
  const [editMode, setEditMode] = React.useState(false);
  const [editModeInitialState, setEditModeInitialState] = React.useState(null);
  const [loadingUnitDateFrames, setLoadingUnitDateFrames] = useState(false);
  const [unitDateFrames, setUnitDateFrames] = useState<Array<DateFrame> | []>(
    [],
  );
  const [loadingToday, setLoadingToday] = useState(false);
  const [loadingTommorow, setLoadingTommorow] = useState(false);
  const [allowedInToday, setAllowedInToday] = useState(allowedToday);
  const [allowedInTommorow, setAllowedInTommorow] = useState(allowedTommorow);

  const tailwind = useTailwind();

  useEffect(() => {
    fetchDateFrames(unitPeopleLinkId);
  }, [unitPeopleLinkId]);

  useFocusEffect(
    useCallback(() => {
      fetchDateFrames(unitPeopleLinkId);
    }, [unitPeopleLinkId]),
  );

  async function fetchDateFrames(linkId) {
    setIsLoading(true);
    try {
      let results: Array<CalendarDateFrame> = await fetchDateFrameByPerson(
        1,
        linkId,
      );
      const foreverDateFrame = results.filter(
        item => item.id === SYSTEM_FOREVER_DATEFRAME_ID,
      );
      setHasForeverDateFrame(foreverDateFrame.length > 0);
      const dateFramesInUnit = results.map(item => {
        return {
          ...item,
          days: convertDateFrameToDays(
            new Date(item.dateFrame.startDate),
            new Date(item.dateFrame.endDate),
          ),
        };
      });
      console.log(dateFramesInUnit);
      const marked = markCalendarDays(dateFramesInUnit);
      setmarkedDates(marked);
      setDateFrames(dateFramesInUnit);
      setIsLoading(false);
    } catch (error) {
      showErrorMessage(error.message);
    }
  }

  const fetchUnitDateFrames = async (unitId: string) => {
    setLoadingUnitDateFrames(true);
    try {
      const res = await fetchDateFramesByUnit(1, unitId);
      setUnitDateFrames(res);
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setLoadingUnitDateFrames(false);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const dateframeExists = dateFrames.find(
      item => item.dateFrame.id === selectedDateFrame,
    );
    if (dateframeExists) {
      showInformationMessage('Person already added to this dateframe.');
      setIsSubmitting(false);
      return;
    }
    const dateFrame = unitDateFrames.find(
      // @ts-ignore
      item => item?.id === selectedDateFrame,
    );

    const dateframeData = {
      unitId,
      startDate: toLocalDateTime(new Date(dateFrame.startDate), true),
      endDate: toLocalDateTime(new Date(dateFrame.endDate), false),
      description: dateFrame.shortDescription,
    };

    try {
      if (isLoggedInUser) {
        await dateFrameRequestAccess(dateframeData);
      } else {
        const newDateFrame = await createDateFrame(dateframeData);
        const dateFrameObj = {
          unitPeopleLinkId,
          description: dateFrame.shortDescription,
          dateFrameIds: [newDateFrame?.id],
        };
        await updatePersonDateFrames(dateFrameObj);
      }
      showSuccessMessage('Dateframe added successfully');
      setModalVisible(false);
      // navigation.goBack();
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeletePress = (unitlinkdateFrameId: string) => {
    showAlert(
      'Confirm',
      'Are you sure you want to unlink this dateframe from user ?',
      () => console.log('NO'),
      async () => {
        try {
          await deleteUnitLinkDateFrame(unitlinkdateFrameId);
          showSuccessMessage('Dateframe unlinked from person');
          await fetchDateFrames(unitPeopleLinkId);
        } catch (error) {
          showErrorMessage(error.message);
        }
      },
    );
  };

  const authorizeForToday = async () => {
    const {todayArguments, todayEndDate, todayStartDate, todayDescription} =
      buildTodayAlertArgs(personName, unitName);
    const request: RequestAccess = {
      unitId,
      startDate: todayStartDate,
      endDate: todayEndDate,
      description: todayDescription,
    };
    setLoadingToday(true);
    await addDateFrameToPerson(request);
    setAllowedInToday(true);
  };

  const addDateFrameToPerson = async (request: RequestAccess) => {
    try {
      const newDateFrame = await createDateFrame({
        unitId: request.unitId,
        startDate: request.startDate,
        endDate: request.endDate,
        shortDescription: request.description,
      });
      const dateFrameObj = {
        unitPeopleLinkId,
        shortDescription: request.description,
        dateFrameIds: [newDateFrame?.id],
      };

      await updatePersonDateFrames(dateFrameObj);
      showSuccessMessage('Dateframe added successfully');
      await fetchDateFrames(unitPeopleLinkId);
      //@ts-ignore
      store.dispatch(GetPeople());
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setLoadingToday(false);
      setLoadingTommorow(false);
    }
  };

  const authorizeForTommorow = async () => {
    const {
      tommorowArguments,
      tommorowStartDate,
      tommorowEndDate,
      tommorowDescription,
    } = buildTommorowAlertArgs(personName, unitName);
    const request: RequestAccess = {
      unitId,
      startDate: tommorowStartDate,
      endDate: tommorowEndDate,
      description: tommorowDescription,
    };
    setLoadingTommorow(true);
    await addDateFrameToPerson(request);
    setAllowedInTommorow(true);
  };

  const renderHeader = () => {
    return (
      <>
        <AuthorizeButtons
          allowedToday={allowedInToday}
          allowedTommorow={allowedInTommorow}
          authorizeForToday={authorizeForToday}
          authorizeForTommorow={authorizeForTommorow}
          loadingToday={loadingToday}
          loadingTommorow={loadingTommorow}
        />
        <Card>
          <CustomCalendar markedDates={markedDates} />
        </Card>
        <Legend />
        <Text style={{marginTop: 16, padding: 8, fontSize: 16}}>
          Dates List
        </Text>
      </>
    );
  };

  return (
    <Container hasDrawer>
      <NavbarWithAction
        hasAdd={!hasForeverDateframe}
        hasBackArrow
        navigation={navigation}
        onClickAdd={() => {
          setEditMode(false);
          setModalVisible(true);
          fetchUnitDateFrames(unitId);
        }}
        hasSearch={false}
        title={TITLE}
        hasMoreMenu={false}
        hasProfile={false}
        hasSignOut={false}
        fullScreen
      />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <FlatList
          data={dateFrames}
          ListEmptyComponent={<EmptyList message="No dateframes found" />}
          renderItem={({item}) => {
            const isForeverDateFrame =
              item.dateFrame.id === SYSTEM_FOREVER_DATEFRAME_ID;
            return (
              <DateFrameItem
                dateFrame={item}
                onClickApprovalsRequired={() => {
                  navigation.navigate('Approvals History', {
                    personName: 'Dateframe',
                    isLoggedInUser: false,
                    approvals: item.approvals,
                    type: 'pending',
                    recordName: item.dateFrame.shortDescription
                      ? item.dateFrame.shortDescription
                      : item.dateFrame.notes,
                  });
                }}
                handleDelete={() =>
                  !isForeverDateFrame && handleDeletePress(item.id)
                }
                handleEdit={() => {
                  if (!isForeverDateFrame) {
                    setEditModeInitialState(item);
                    setEditMode(true);
                    setPanelActive(true);
                  }
                }}
                isForeverDateFrame={isForeverDateFrame}
              />
            );
          }}
          ListHeaderComponent={renderHeader()}
          ListFooterComponent={
            <View style={{height: Platform.OS === 'android' ? 30 : 120}} />
          }
        />
      )}
      <Modal isOpen={modalVisible} onClose={setModalVisible} size="xl">
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Link dateframe to person</Modal.Header>
          <Modal.Body>
            <FormContainer>
              <Text style={tailwind('mb-2 flex-1')}>
                Select a Date frame or create a new one
              </Text>
              <HStack style={tailwind('flex-1 mt-3')}>
                {loadingUnitDateFrames ? (
                  <HStack>
                    <ActivityIndicator size="small" />
                    <Text style={{fontSize: 12, marginTop: 12, marginLeft: 12}}>
                      Loading dateframes...
                    </Text>
                  </HStack>
                ) : (
                  <Select
                    placeholder="Select dateframe"
                    py="4"
                    minWidth={200}
                    style={{flex: 1}}
                    selectedValue={selectedDateFrame}
                    onValueChange={val => {
                      setSelectedDateFrame(val);
                    }}>
                    {unitDateFrames.map((item: DateFrame) => (
                      <Select.Item
                        label={item.shortDescription}
                        value={item.id}
                        key={item.id}
                      />
                    ))}
                  </Select>
                )}
                <Button
                  style={{flex: 1}}
                  ml={4}
                  leftIcon={<FeatherIcon name="plus" size={20} />}
                  onPress={() => {
                    setModalVisible(false);
                    setPanelActive(true);
                  }}
                  variant="outline">
                  Create new
                </Button>
              </HStack>
              <Button
                isDisabled={isSubmitting || !selectedDateFrame}
                my="4"
                py="4"
                onPress={handleSubmit}>
                Submit
              </Button>
            </FormContainer>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <CreateDateFramePanel
        editMode={editMode}
        initialState={editModeInitialState}
        unitId={unitId}
        unitPeopleLinkId={unitPeopleLinkId}
        navigation={navigation}
        panelActive={panelActive}
        setPanelActive={(state: boolean) => setPanelActive(state)}
        onSubmitSuccess={() => fetchDateFrames(unitPeopleLinkId)}
        isLoggedInUser={isLoggedInUser}
      />
    </Container>
  );
};

const DateFrameItem = ({
  dateFrame,
  onClickApprovalsRequired,
  handleDelete,
  handleEdit,
  isForeverDateFrame,
}) => {
  const tailwind = useTailwind();
  
  return (
    <Card style={tailwind('rounded my-2 p-2 mx-2')}>
      <View
        style={{
          // flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <View>
          <Text style={tailwind('text-lg')}>
            {dateFrame.dateFrame.shortDescription
              ? dateFrame.dateFrame.shortDescription
              : dateFrame.dateFrame.notes}
          </Text>

          <Text style={tailwind(' text-xs')}>
            {new Date(dateFrame.dateFrame.startDate).toLocaleDateString()} -{' '}
            {new Date(dateFrame.dateFrame.endDate).toLocaleDateString()}
          </Text>
          <RequireApproval
            approvals={dateFrame.approvals}
            onClickApprovalsRequired={() => onClickApprovalsRequired()}
          />
        </View>
        {!isForeverDateFrame && (
          <Menu
            placement="right top"
            trigger={triggerProps => {
              return (
                <Pressable {...triggerProps} style={tailwind('pl-4 pt-2')}>
                  <Ionicon name="ellipsis-vertical" size={22} />
                </Pressable>
              );
            }}>
            <Menu.Item onPress={() => handleEdit()}>Edit</Menu.Item>
            <Menu.Item onPress={() => handleDelete()}>Delete</Menu.Item>
          </Menu>
        )}
      </View>
    </Card>
  );
};

export default UnitCalendar;
