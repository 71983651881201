// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react';
import {
  View,
  FlatList,
  LogBox,
  Alert,
  RefreshControl,
  Image,
} from 'react-native';
import {Divider, ActivityIndicator, Menu, Card} from 'react-native-paper';
import Text from '../../../components/Text';
import useTailwind from '../../../components/useTailwind';
import Center from '../../../components/Center';
import {useDispatch, useSelector} from 'react-redux';
import {styles} from '../../../styles';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {MySearchbar, NavbarWithAction} from '../../../components';
import {useFocusEffect} from '@react-navigation/native';
import {deletePetById} from '../../../api/pets';
import {RootStore} from '../../../redux/store';
import {GetPets} from '../petReducer';
import {useCallback} from 'react';
import {permissionToBool} from '../../../utils';
import IconButton from '../../../components/IconButton';
import Container from '../../../components/Container';
import ZoomableImage from '../../../components/ZoomableImage';

const Pets = ({navigation}) => {
  const currentUnitLink = useSelector(
    (state: RootStore) =>
      state.units.unitLinks.filter(link => link.isDefault === true)[0],
  );
  const {pets, loading} = useSelector((state: RootStore) => state.pets);
  const [searchTerm, setSearchTerm] = useState('');
  const [isNavbarShown, setIsNavbarShown] = useState(true);
  const dispatch = useDispatch();
  const [refreshing, setRefreshing] = useState(false);

  const tailwind = useTailwind();

  useFocusEffect(
    React.useCallback(() => {
      LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
      if (currentUnitLink) {
        dispatch(GetPets(searchTerm));
      }
    }, [searchTerm]),
  );

  const showDeleteUnitPrompt = (petId: string) => {
    Alert.alert(
      '',
      'Do you really want to delete pet ?',
      [
        {
          text: 'NO',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: 'YES',
          onPress: () => deletePet(petId),
        },
      ],
      {
        cancelable: true,
      },
    );
  };

  const deletePet = async (petId: string) => {
    try {
      await deletePetById(petId);
      showSuccessMessage('Pet deleted successfully');
      dispatch(GetPets(''));
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    dispatch(GetPets(''));
    setRefreshing(false);
  }, []);

  const MainComponent = () => {
    return (
      <>
        {loading ? (
          <ActivityIndicator
            animating={true}
            color={styles.primaryColor.color}
            style={tailwind('flex-1 items-center content-center')}
          />
        ) : (
          <FlatList
            refreshControl={
              <RefreshControl onRefresh={onRefresh} refreshing={refreshing} />
            }
            data={pets}
            style={tailwind('my-3 mx-2 flex-1 ')}
            ListFooterComponent={<View style={tailwind('h-8')} />}
            ListEmptyComponent={<Center>No pets found</Center>}
            renderItem={({item}) => (
              <PetsListItem
                pet={item}
                onSelectDelete={() => showDeleteUnitPrompt(item.id)}
                itemClickHandler={() =>
                  navigation.navigate('Pet Detail', {petId: item.id})
                }
                onSelectEdit={() =>
                  navigation.navigate('Edit Pet', {pet: item})
                }
                currentUnitLink={currentUnitLink}
              />
            )}
          />
        )}
      </>
    );
  };

  return (
    <Container>
      <>
        {isNavbarShown ? (
          <NavbarWithAction
            navigation={navigation}
            hasBackArrow
            title="Pets"
            showCurrentUnit
            onClickSearch={() => setIsNavbarShown(false)}
            hasAdd={permissionToBool(currentUnitLink?.petPermission?.charAt(3))}
            hasSearch={pets.length > 0}
            onClickAdd={() => navigation.navigate('Add Pet', {pet: null})}
            fullScreen
          />
        ) : (
          <MySearchbar
            placeholder="Search  name"
            setValue={(val: string) => {
              setSearchTerm(val);
            }}
            resetSearch={() => {
              setIsNavbarShown(true);
              setSearchTerm('');
            }}
          />
        )}
      </>
      <Divider />
      <MainComponent />
    </Container>
  );
};

const PetsListItem = ({
  pet,
  onSelectEdit,
  onSelectDelete,
  itemClickHandler,
  currentUnitLink,
}) => {
  const tailwind = useTailwind();
  const [menuVisible, setMenuVisible] = useState(false);
  const imageUrl = pet.picture
    ? `data:image/jpeg;base64,${pet.picture}`
    : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAG1BMVEXr6+vY2Njp6ena2trd3d3g4ODj4+Pm5ubW1tbKf8WKAAADqklEQVR4nO3a25biIBBGYUMOzPs/8aiQSOAHk8zYTbn2d9m0LsqC4pDcbgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY9w83S2u1u5D+0926f+ah2jyqnkZY/Mom/vnpuFF5OlNswFpBMMwZ61u3DVbDHEZhlYM+/iHsTpXu5Xl6G432ea8Nc9x/4oQhjFpzRNsMYlFCnfjtN1qgitDSNJUpvC+ovxmdy9QMbzSNInGsfV1HSqnYRKEV42DsYkoI1zLqW78igjjkiDqzLdEGKqJqkLfEmGoprIKmYtQR9GK0Fot1eUy5Kk1gg1pVJNmFbJDLerNCM2dgvVkq0dobRqq09PQrDTmpqHOVAhDViFja8WTCGOuthhMoUxidV9q5/zr054WM7F+tkiXCt9tVXXzM6TXBXBRbLau50vJFqCP39FlkH6LZ1xPulnRfM21bO+95tZN23d0uP7vRt4U0+hHEcfD8va/+ys9+4G3Vo7k3nvaFZMk42u6soz3djOVr+JbwpYpzs7sA3HWjvMaeF5+eiuvRd1MRpnzurO7v5d7oL6SKPZoJztY7tT7moliq3luK/3PX/BpajN9quCrffqnOnuJivBMqZAHqs9194L2Ffd78kbgc929QEZ4fCLpW50P9ve8xon+CAOnfi+H2eFhKj/d12qh+3i4msoR0NnmW46zMgvOe7HBkdf8vb2CImtFFuH98Bfk7xDpCH+w94eo+9E0wrjT3prSDMkI+9qW3t4+uJ7LK41XjPKR+C/E8IaoNVuEiy6W21j9YyCFcibGXjp9w5+EUf4AnS0VQVlOwzjUa+UukNpHe5OnKkwl/Rxm+59nKEX++wywOAY/x2A7wDWY7MfpbLFPTEU3q1Nw/0Psfpze1vqdZM14TrEDAYYQk8nadYC3beELd2uHAoxZjHdvBt6Jds4v8dHDwQDjXLx/bqncyvXqbZHJQjSn8tqM1Nv17yGVN58qutzDtMnn+A39LoE1JyZhYG0qVt6JaujwuNR0cow+9L8Qps7U0S2JpurphRTaKjany0xgKImXUmhpJp4vpIGdcnpxkBpaEy8OUju1pnX11GZld3plMQysTMTrEfb1ULSOCImwf/Va+nh2OI31Umulllbu2MY5XqU5r59FGdq2iRuMac4e+y7iZzCTwmKcjrPajvl8c2dlRxPE10rvM2+S4QV+Xt97HvMkG+Ae7114967fz/9SL2gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAX+wvqtkVJSpt2GwAAAAASUVORK5CYII=';

  return (
    <Card style={tailwind('rounded my-2')} onPress={() => itemClickHandler()}>
      <View style={tailwind('flex-row p-2 my-1 justify-between')}>
        <ZoomableImage
          title={pet.petName}
          style={[styles.userImage]}
          source={{
            uri: imageUrl,
          }}
        />
        <View style={tailwind('flex-1 ml-2 flex-col justify-around')}>
          <View style={tailwind('flex-row')}>
            <Text style={tailwind('text-lg')}>
              {pet.petName ? pet.petName : 'Pet name not provided'}
            </Text>
          </View>
          <Text style={tailwind('')}>
            {pet.petOwnerName ? pet.petOwnerName : 'Pet owner not provided'}
          </Text>
          <View style={tailwind('flex-1 flex-row mt-2')}>
            <View style={tailwind('flex-1 flex-row  justify-between')}>
              <Text>
                {pet.petTagNumber ? pet.petTagNumber : 'Tag no not provided'} .{' '}
                {pet.typeOfPet ? pet.typeOfPet : 'Type not provided'}
              </Text>
            </View>
          </View>
        </View>
        {(permissionToBool(currentUnitLink?.petPermission.charAt(1)) ||
          permissionToBool(currentUnitLink?.petPermission.charAt(4))) && (
          <View style={tailwind('mt-3 mr-4')}>
            <Menu
              visible={menuVisible}
              onDismiss={() => setMenuVisible(false)}
              anchor={
                <IconButton
                  icon="ellipsis-vertical"
                  iconColor={'#718096'}
                  size={24}
                  onPress={() => setMenuVisible(true)}
                />
              }>
              {permissionToBool(currentUnitLink?.petPermission.charAt(1)) && (
                <Menu.Item
                  onPress={() => {
                    setMenuVisible(false);
                    onSelectEdit();
                  }}
                  title="Edit"
                />
              )}
              {permissionToBool(currentUnitLink?.petPermission.charAt(4)) && (
                <Menu.Item
                  onPress={() => {
                    setMenuVisible(false);
                    onSelectDelete();
                  }}
                  title="Delete"
                  icon="link-outline"
                />
              )}
            </Menu>
          </View>
        )}
      </View>
    </Card>
  );
};

export default Pets;
