import React, {useState} from 'react';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {TouchableOpacity} from 'react-native';
import useTailwind from '../../../components/useTailwind';
import {HStack, Spacer} from 'native-base';
import {colorSwatches} from '../../../styles';
import {UserCreds} from '../types';

const DeleteAccountModal = ({visible, closeModal, onPressDelete}) => {
  const [userCreds, setUserCreds] = useState<UserCreds>({
    username: null,
    password: null,
  });
  const [passwordHidden, setPasswordHidden] = useState(true);
  const tailwind = useTailwind();



  return (
    <Modal isOpen={visible} onClose={closeModal}>
      <Modal.Content>
        <Modal.Header>Confirmation</Modal.Header>
        <Modal.Body
          _scrollview={{
            keyboardShouldPersistTaps: 'handled',
          }}>
          <Text>
            To delete your account, please type your username and password.
          </Text>
          <Input
            mt={4}
            mb={4}
            placeholder="Username"
            value={userCreds.username}
            autoCorrect={false}
            autoCapitalize="none"
            onChangeText={text => setUserCreds({...userCreds, username: text})}
          />
          <Input
            mt={4}
            mb={4}
            placeholder="Password"
            value={userCreds.password}
            autoCorrect={false}
            autoCapitalize="none"
            secureTextEntry={passwordHidden}
            InputRightElement={
              <TouchableOpacity
                onPress={() => setPasswordHidden(!passwordHidden)}>
                <FeatherIcon
                  name={passwordHidden ? 'eye-off' : 'eye'}
                  size={24}
                  color="gray"
                  style={tailwind('m-2')}
                />
              </TouchableOpacity>
            }
            onChangeText={text => setUserCreds({...userCreds, password: text})}
          />
          <HStack>
            <Button
              style={{
                backgroundColor: colorSwatches.red[400],
              }}
              onPress={() => {
                onPressDelete(userCreds);
                closeModal();
              }}>
              Delete
            </Button>
            <Spacer />
            <Button
              onPress={() => {
                closeModal();
              }}>
              Cancel
            </Button>
          </HStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default DeleteAccountModal;
