import {jwtDecode} from 'jwt-decode';

export default function isTokenExpired(token: string) {
  let decodedToken = jwtDecode(token);
  let currentDate = new Date();

  // if (decodedToken.exp * 1000 < currentDate.getTime()) {
  //   return true;
  // } else {
  //   return false;
  // }

  return true;
}
