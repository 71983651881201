import React, {useState, useEffect} from 'react';
import {
  SafeAreaView,
  ScrollView,
  View,
  useWindowDimensions,
} from 'react-native';
import Text from '../../../components/Text';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import useTailwind from '../../../components/useTailwind';
import {
  RegisterActionBar,
  RegisterPrevButton,
} from '../../../components';
import {useSelector} from 'react-redux';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {RootStore} from '../../../redux/store';
import {residentApiWithAuth} from '../../../api/resident';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import TextInput from '../../../components/TextInput';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { initiateSMS } from '../../App/service';

const phoneConfirmationValidationSchema = yup.object().shape({
  phoneConfirmationCode: yup.string().required('Code is required'),
});

const PhoneConfirmation = ({navigation, route}) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const registerValues = useSelector((state: RootStore) => state.register);
  const {personId, errors} = route.params;
  const {control, handleSubmit} = useForm({
    defaultValues: {
      phoneConfirmationCode: '',
    },
    resolver: yupResolver(phoneConfirmationValidationSchema),
  });

  const {width} = useWindowDimensions();

  const resendPhoneCode = async () => {
    setIsResending(true);
    try {
      const res = await residentApiWithAuth().post(
        `/people/sendPhoneCode/${personId}`,
      );
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        showSuccessMessage('The code has been sent to your cell phone');
      }
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setIsResending(false);
    }
  };

  const confirmPhoneCode = async ({
    phoneConfirmationCode,
  }: {
    phoneConfirmationCode: string;
  }) => {
    setIsConfirming(true);
    try {
      const res = await residentApiWithAuth().post(
        `/people/confirmPhone/${personId}/${phoneConfirmationCode}`,
      );
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        showSuccessMessage('Phone confirmed successfully');
        navigation.navigate('Email Confirmation', {personId});
      }
    } catch (error) {
      console.log('error', error.response);
      showErrorMessage('Invalid code');
    } finally {
      setIsConfirming(false);
    }
  };

  const onClose = () => setIsOpen(false);

  const onYesPressed = () => {
    navigation.navigate('Email Confirmation', {personId});
  };

  const onNoPressed = () => {
    // Send text message to [954-449-6239]
    initiateSMS('954-449-6239', 'START');
  };

  useEffect(() => {
    if(errors?.length > 0){
      setIsOpen(true)
    }
  }, [errors]);
  
  const tailwind = useTailwind();

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          tailwind('flex-1 my-4'),
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <ScrollView
          style={tailwind('mx-2 mb-4')}
          keyboardShouldPersistTaps="handled">
          <RegisterActionBar
            step={5}
            hasSkip={true}
            onSkip={() => navigation.navigate('Email Confirmation', {personId})}
          />
          <View style={tailwind('mx-6 mt-4')}>
            <Heading size="lg">Phone Confirmation</Heading>
            {isResending && (
              <View style={tailwind('flex-row mt-8')}>
                <Text style={tailwind('mb-4 ml-2 text-lg')}>
                  Sending code ...
                </Text>
              </View>
            )}
            <View style={tailwind('flex-row mt-8 mb-2')}>
              <Ionicons
                name="checkmark-circle-outline"
                color="#47B275"
                size={24}
              />
              <Text style={tailwind('ml-2 text-lg')}>
                {`Type the code you received via ${registerValues.phone1}.`}
              </Text>
            </View>
            <View style={tailwind('mt-2 flex-1')}>
              <TextInput
                //@ts-ignore
                control={control}
                name="phoneConfirmationCode"
                placeholder="e.g 1234X"
                label="Phone Code"
              />
              <Button
                onPress={() => resendPhoneCode()}
                style={tailwind('mt-6 flex-1')}
                variant="link">
                Resend Code
              </Button>
            </View>
          </View>
        </ScrollView>
        <HStack style={{margin: 24, marginTop: 32}}>
          <RegisterPrevButton />
          <Button
            // isDisabled={!isValid}
            onPress={handleSubmit(confirmPhoneCode)}
            isLoading={isConfirming}
            isLoadingText="Confirming code ...."
            style={tailwind('mx-2 flex-1')}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
      {errors?.length > 0 && (
        <ConfirmationDialog
          isOpen={isOpen}
          onClose={onClose}
          onNoPressed={onNoPressed}
          onYesPressed={onYesPressed}
          negativeLabel='UNBLOCK '
          positiveLabel='SKIP'
          description={
            errors[0] === 'Attempt to send to unsubscribed recipient'
              ? 'This phone number has block our SMS service.Press UNBLOCK to restart or click skip button, and you will not receive any SMS messages.'
              : 'Invalid phone number'
          }
        />
      )}
    </ScreenWrapper>
  );
};

export default PhoneConfirmation;
