import React, {useState} from 'react';
import {ScrollView, View} from 'react-native';
import Text from '../../../components/Text';
import {ActivityIndicator, Card, Divider} from 'react-native-paper';
import {styles} from '../../../styles';
import {Navbar} from '../../../components';
import {useEffect} from 'react';
import {showErrorMessage} from '../../../service/flashMessage';
import {Pet} from '../types';
import {fetchPetById} from '../../../api/pets';
import Container from '../../../components/Container';
import ZoomableImage from '../../../components/ZoomableImage';
import useTailwind from '../../../components/useTailwind';

const PetDetail = ({navigation, route}) => {
  const {petId} = route.params;
  const [isLoading, setIsLoading] = useState(true);
  const [pet, setPet] = useState<Pet>({});

  const tailwind = useTailwind();

  const fetchPet = async (id: string) => {
    try {
      const res: Pet = await fetchPetById(id);
      setPet(res);
      setIsLoading(false);
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  useEffect(() => {
    fetchPet(petId);
  }, [petId]);

  return (
    <Container>
      <Navbar navigation={navigation} title="Pet details" />
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Card style={tailwind('mx-2 mt-4 pb-2')}>
            <View style={tailwind('flex-row justify-between p-2')}>
              <ZoomableImage
                title={pet.petName}
                source={{
                  uri: pet.picture
                    ? `data:image/jpeg;base64,${pet.picture}`
                    : 'https://nichemodels.co/wp-content/uploads/2019/03/user-dummy-pic.png',
                }}
                style={styles.image}
              />
              <View style={tailwind('flex-1 ml-4')}>
                <Text style={styles.userName}>{pet.petName}</Text>
                <Text style={styles.email}>{pet.petOwnerName}</Text>
              </View>
            </View>
          </Card>
          <ScrollView
            style={tailwind('mx-2 py-4')}
            showsVerticalScrollIndicator={false}>
            <Card style={[tailwind('p-2 mb-8')]}>
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Type of pet</Text>
                <Text style={styles.detailsText}>{pet.typeOfPet}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Color</Text>
                <Text style={styles.detailsText}>{pet.colorOfPet}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Weight</Text>
                <Text style={styles.detailsText}>{pet.colorOfPet}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Weight</Text>
                <Text style={styles.detailsText}>{pet.petWeight}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row my-3')}>
                <Text style={styles.textLabel}>Vetinerian</Text>
                <Text style={styles.detailsText}>{pet.vetinerian}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Vet Phone Number</Text>
                <Text style={styles.detailsText}>{pet.vetPhoneNumber}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Pet Tag Number</Text>
                <Text style={styles.detailsText}>{pet.petTagNumber}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Vaccine one</Text>
                <Text style={styles.detailsText}>{pet.vacineOne}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Vacine One Expiration</Text>
                <Text style={styles.detailsText}>
                  {new Date(pet.vacineOneExpiration).toLocaleDateString()}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Vaccine two</Text>
                <Text style={styles.detailsText}>{pet.vacineTwo}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Vacine Two Expiration</Text>
                <Text style={styles.detailsText}>
                  {new Date(pet.vacineTwoExpiration).toLocaleDateString()}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Vaccine three</Text>
                <Text style={styles.detailsText}>{pet.vacineThree}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Vacine Three Expiration</Text>
                <Text style={styles.detailsText}>
                  {new Date(pet.vacineThreeExppiration).toLocaleDateString()}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Vaccine four</Text>
                <Text style={styles.detailsText}>{pet.vacineFour}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Vacine Four Expiration</Text>
                <Text style={styles.detailsText}>
                  {new Date(pet.vacineFourExpiration).toLocaleDateString()}
                </Text>
              </View>
            </Card>
          </ScrollView>
        </>
      )}
    </Container>
  );
};

export default PetDetail;
