// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {
  ScrollView,
  View,
  Image,
  useWindowDimensions,
  SafeAreaView,
} from 'react-native';
import Text from '../../../components/Text';
import useTailwind from '../../../components/useTailwind';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';
import {
  DisabledInput,
  LoadingSpinner,
  Navbar,
  Permissions,
} from '../../../components';
import {styles} from '../../../styles';
import {dataFrameInitialState, initialLinkState} from '../utils';
import {fetchPerson} from '../../../api/people';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {
  createUnitPeopleLink,
  fetchLinksByPerson,
  getUnitLinkById,
} from '../../../api/links';

import {useDispatch, useSelector} from 'react-redux';
import {sendAlert} from '../../../service/configureNotifications';
import {sendAction} from '../../../api/alert';
import {CreateUnit} from '../../Units/unitsReducer';
import {CreatePeople, GetPeople} from '../../People/peopleReducer';
import {RootStore} from '../../../redux/store';
import {User} from '../../../@types';
import {UnitLink} from '../types';
import {showAlert} from '../../../service';
import FormContainer from '../../../components/FormContainer';
import isWeb from '../../../utils/isWeb';
import Container from '../../../components/Container';

const CreateUnitPeopleLink = ({route, navigation}) => {
  const {
    notification,
    unitId,
    peopleId,
    notificationResponse,
    notificationId,
    isDirectLink, //create dateframe,link to unit, create peopleDateFrame, send Request access successful.
  } = route.params;
  const unitLink = useSelector(
    (state: RootStore) => state?.units?.unitLinks,
  ).filter(link => link.isDefault === true)[0];
  const [unitPeopleState, setUnitPeopleState] = useState({
    ...initialLinkState,
    unitId: notification ? unitId : unitLink.unit.id, // //correct from unitlink
    peopleId: peopleId,
  });
  const [dateFrameState, setDateFrameState] = useState({
    ...dataFrameInitialState,
    unitId: notification ? unitId : unitLink.unit.id,
  });
  const initialUser: User = {};
  const [requestingPerson, setRequesingPerson] = useState(initialUser);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [isFetchingPerson, setIsFetchingPerson] = useState(false);

  const {width} = useWindowDimensions();
  const tailwind = useTailwind();

  const fetchRequestingPerson = async (id: string) => {
    setIsFetchingPerson(true);
    try {
      const person: User = await fetchPerson(id);
      setUnitPeopleState({
        ...unitPeopleState,
        firstName: person.firstName,
        lastName: person.lastName,
        city: person.city,
        state: person.state,
        zip: person.zip,
        company: person.company,
        addressLine1: person.addressLine1,
        addressLine2: person.addressLine2,
        phone: person.phone1,
        email: person.email,
        peopleId: person.id,
        profilePicture: person.profilePicture,
      });
      setRequesingPerson(person);
    } catch (error) {
      showErrorMessage(error.message);
    }
    setIsFetchingPerson(false);
  };

  useEffect(() => {
    fetchRequestingPerson(peopleId);
  }, [peopleId, unitId]);

  const linkPerson = async () => {
    isDirectLink ? await directLinkPerson() : await linkPersonToUnit();
  };

  const directLinkPerson = async () => {
    let unitPeopleLink = unitPeopleState;
    //Delete peopleType object from permissions component
    delete unitPeopleLink.peopleType;

    try {
      const link: UnitLink = await createUnitPeopleLink({
        ...unitPeopleLink,
        dateFrames: [],
      });

      showSuccessMessage('Person added to unit successfully');

      //If person is owner, hide add dateframe prompt
      if (!unitPeopleState.addForeverDateFrame) {
        promptAddDateFrames(link.id);
      } else {
        dispatch(GetPeople());
        navigation.navigate('People');
      }
    } catch (e) {
      showErrorMessage(e.message);
    }
    setIsSubmitting(false);
  };

  const promptAddDateFrames = (linkId: string) => {
    showAlert(
      'Add dateframes',
      'Do you wish to add dateframes to this person ?',
      () => {
        dispatch(GetPeople());
        navigation.navigate('People');
      },
      () =>
        navigation.navigate('Create Person DateFrame', {
          unitPeopleLinkId: linkId,
        }),
    );
  };

  const linkPersonToUnit = async () => {
    try {
      const personUnitLinks = await fetchLinksByPerson(notification.sender.id);
      const personLinkToUpdate = personUnitLinks.filter(
        unitLink => unitLink.unit.id === notification.unit.unitId,
      );
      const personLinkToUpdateId = personLinkToUpdate[0]?.id;

      //Send response
      await sendAction({
        alertDetailId: notificationId,
        action: notificationResponse,
      });

      const unitPeopleLinked: UnitLink =
        await getUnitLinkById(personLinkToUpdateId);

      //Send alert to people in unit and/or to requesting person
      await sendAlert(
        unitPeopleLinked,
        unitPeopleLinked.person.id,
        unitPeopleLinked.unit.id,
      );

      dispatch(CreateUnit(unitPeopleLinked));
      dispatch(CreatePeople(unitPeopleLinked));

      showSuccessMessage('Person linked to unit successfully');

      if (!unitPeopleState.addForeverDateFrame) {
        promptAddDateFrames(unitPeopleLinked.id);
      }
      dispatch(GetPeople());
      navigation.navigate('People');
    } catch (e) {
      showErrorMessage(e.message);
    }
    setIsSubmitting(false);
  };

  function updateStateWithPermissions(permissionsState) {
    const permissionsObj = Object.assign({}, permissionsState);
    delete permissionsObj.peopleType;
    setUnitPeopleState({
      ...unitPeopleState,
      ...permissionsObj,
      peopleTypeId: permissionsState.peopleType.id,
    });
  }

  const renderCustomComponent = () => {
    return (
      <>
        <DisabledInput title="First Name" value={requestingPerson.firstName} />
        <DisabledInput title="Last name" value={requestingPerson.lastName} />
        <DisabledInput title="Phone 1" value={requestingPerson.phone1} />
        <DisabledInput title="Email" value={requestingPerson.email} />
        <Text style={styles.textInputLabel}>Profile picture</Text>
        {requestingPerson.profilePicture ? (
          <Image
            source={{
              uri: `data:image/jpeg;base64,${requestingPerson.profilePicture}`,
            }}
            style={styles.profileImage}
          />
        ) : (
          <Image
            source={require('../../../images/default-user-avatar.png')}
            style={styles.profileImage}
            resizeMode="contain"
          />
        )}
        <DisabledInput title="Company" value={requestingPerson.company} />
        <>
          <Text style={styles.textInputLabel}>Note</Text>
          <TextArea
            placeholder="Enter notes"
            value={dateFrameState.notes}
            onChangeText={text =>
              setDateFrameState({...dateFrameState, notes: text})
            }
          />
        </>
      </>
    );
  };

  return (
    <Container>
      <Navbar navigation={navigation} title="Link person to unit" />
      <FormContainer>
        <ScrollView
          keyboardShouldPersistTaps="handled"
          style={tailwind('mx-2 mb-4')}
          showsVerticalScrollIndicator={false}>
          {isFetchingPerson ? (
            <LoadingSpinner />
          ) : (
            <>
              {isDirectLink && (
                <DisabledInput
                  title="Unit *"
                  value={
                    unitLink.unitNickname
                      ? unitLink.unitNickname
                      : unitLink.unit.unitName
                  }
                />
              )}
              <Permissions
                initialPeopleState={{}}
                updateParentState={childState => {
                  updateStateWithPermissions(childState);
                }}
                isEditMode
                isCreate={true}
                renderCustomComponent={renderCustomComponent}
              />
            </>
          )}
        </ScrollView>
        <Button
          style={
            isWeb ? {marginTop: 24} : {marginBottom: 76, marginHorizontal: 8}
          }
          isLoading={isSubmitting}
          isDisabled={isFetchingPerson}
          size="lg"
          isLoadingText="Submitting ..."
          onPress={() => {
            if (!unitPeopleState.unitId) {
              showErrorMessage('Please select a unit');
            }
            if (unitPeopleState.peopleTypeId === null) {
              showErrorMessage('Please select people type');
            }
            if (unitPeopleState.unitId && unitPeopleState.peopleTypeId) {
              setIsSubmitting(true);
              linkPerson();
            }
          }}>
          Add person
        </Button>
      </FormContainer>
    </Container>
  );
};

export default CreateUnitPeopleLink;
