import React from 'react';
import {ScrollView, View} from 'react-native';
import Text from '../../../components/Text';
import {Card, Divider} from 'react-native-paper';
import useTailwind from '../../../components/useTailwind';
import {styles} from '../../../styles';
import {Navbar} from '../../../components';

const DeliveryDetail = ({navigation, route}) => {
  const {delivery} = route.params;
  const tailwind = useTailwind();

  return (
    <View style={[tailwind('flex-1')]}>
      <Navbar navigation={navigation} title="Delivery details" />
      <>
        <ScrollView
          style={tailwind('mx-2 py-4')}
          showsVerticalScrollIndicator={false}>
          <Card style={[tailwind('p-2 mb-8')]}>
            <View style={tailwind('flex-1 flex-row justify-between')} />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Dateframe start date</Text>
              <Text style={styles.detailsText}>
                {new Date(delivery?.dateFrame?.startDate).toLocaleString()}
              </Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Dateframe end date</Text>
              <Text style={styles.detailsText}>
                {new Date(delivery?.dateFrame?.endDate).toLocaleString()}
              </Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row my-3')}>
              <Text style={styles.textLabel}>Dateframe description</Text>
              <Text style={styles.detailsText}>
                {delivery?.dateFrame?.description}
              </Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Company</Text>
              <Text style={styles.detailsText}>{delivery.company}</Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Required</Text>
              <Text style={styles.detailsText}>{delivery.required}</Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Expected time</Text>
              <Text style={styles.detailsText}>{delivery.expectedTime}</Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Authorization to</Text>
              <Text style={styles.detailsText}>{delivery.authorizationTo}</Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Person authorizing</Text>
              <Text style={styles.detailsText}>{delivery.authorizationTo}</Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Created at</Text>
              <Text style={styles.detailsText}>
                {new Date(delivery.createdTimeDate).toLocaleDateString()}
              </Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Instructions</Text>
              <Text style={styles.detailsText}>{delivery.instructions}</Text>
            </View>
          </Card>
        </ScrollView>
      </>
    </View>
  );
};

export default DeliveryDetail;
