import React, {useRef, useState} from 'react';
import {
  ScrollView,
  View,
  useWindowDimensions,
} from 'react-native';
import Text from '../../../components/Text';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import useTailwind from '../../../components/useTailwind';
import {
  RegisterActionBar,
  RegisterPrevButton,
} from '../../../components';
import {useDispatch, useSelector} from 'react-redux';
import {RegistrationData} from '../types';
import {RootStore} from '../../../redux/store';
import {setRegisterData} from '../registerReducer';
import ScreenWrapper from '../components/ScreenWrapper';
import {SafeAreaView} from 'react-native';
import isWeb from '../../../utils/isWeb';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import TextInput from '../../../components/TextInput';
import { checkUser } from '../api';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

const usernameValidationSchema = yup.object().shape({
  username: yup
    .string()
    .required('Username is required')
    .min(4, 'Username can only have a minimum length of 4')
    .max(30, 'Username can have a maximum length of 30'),
});

const UserCredentials = ({navigation}) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const registerValues = useSelector((state: RootStore) => state?.register);
  const scrollViewRef = useRef<ScrollView>(null);

  const {control, handleSubmit} = useForm({
    defaultValues: {
      username: registerValues.email,
    },
    resolver: yupResolver(usernameValidationSchema),
  });

  const {width} = useWindowDimensions();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const closeDialog =()=> setDialogOpen(false);

  const handleUsernameSubmit = async (values: {
    username: string;
  }) => {
    setLoading(true);
    const usernameExists = await checkUser(values.username);
    setLoading(false);

    if(usernameExists){
      setDialogOpen(true);
      return;
    }

    const registrationData: RegistrationData = {
      username: values.username,
    };
    //@ts-ignore
    dispatch(setRegisterData(registrationData));

    navigation.navigate('Invite Code');
  };

  const tailwind = useTailwind();

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          tailwind('flex-1 my-4'),
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <RegisterActionBar step={3} />
        <ScrollView
          style={tailwind('mx-2 mb-8')}
          keyboardShouldPersistTaps="handled"
          ref={scrollViewRef}
          showsVerticalScrollIndicator>
          <View style={tailwind('mx-6 mt-4')}>
            <Heading size="lg">Username</Heading>
            <Text style={tailwind('mt-8 text-lg')}>
              Type a memorable to secure your account.
            </Text>
            <TextInput
              //@ts-ignore
              control={control}
              name="username"
              placeholder="e.g johndoe"
              label="Username *"
              autoCapitalize="none"
            />
          </View>
        </ScrollView>
        <HStack style={{margin: 16}}>
          <RegisterPrevButton />
          <Button
            onPress={handleSubmit(handleUsernameSubmit)}
            isLoading={loading}
            isLoadingText={'Checking ...'}
            style={tailwind('mx-2 flex-1')}>
            {'Next'}
          </Button>
        </HStack>
      </SafeAreaView>
      <ConfirmationDialog
        isOpen={dialogOpen}
        onClose={closeDialog}
        description="Username already exist, please try a different one."
        positiveLabel='OK '
        negativeLabel=''
        onYesPressed={() => {}}
        onNoPressed={() => {}}
      />
    </ScreenWrapper>
  );
};

export default UserCredentials;
