import React, {useState} from 'react';
import {View} from 'react-native';
import Text from '../../../components/Text';
import useTailwind from '../../../components/useTailwind';
import {styles} from '../../../styles';
import Button from '../../../components/Button';
import HStack from '../../../components/HStack';
import {Modal, Select} from 'native-base';
import {makeId} from '../../../utils';

export default function FilterPanel({
  panelActive,
  setPanelActive,
  updateParams,
}) {
  const statuses = [
    {
      label: 'Out',
      value: 'Out',
    },
    {
      label: 'Returned',
      value: 'Returned',
    },
  ];
  const [selectedStatus, setSelectedStatus] = useState(null);

  const tailwind = useTailwind();

  const setFilters = () => {
    updateParams({status: selectedStatus});
    setPanelActive(false);
  };

  const resetFilters = () => {
    updateParams({status: null});
    setSelectedStatus(null);
    setPanelActive(false);
  };

  const KeyLogsFilterSection = () => {
    const tailwind = useTailwind();
    return (
      <View>
        <Text style={styles.panelTitle}>Filter visitor logs</Text>
        <View
          style={{
            margin: 8,
            marginBottom: 0,
            justifyContent: 'space-between',
          }}>
          <Text style={{marginTop: 16}}>Status</Text>
          <Select
            maxWidth={350}
            minWidth={200}
            selectedValue={selectedStatus}
            onValueChange={value => {
              setSelectedStatus(value);
            }}
            placeholder="*Select status*">
            {statuses.map(event => (
              <Select.Item
                key={makeId(16)}
                label={event.label}
                value={event.value}
              />
            ))}
          </Select>
        </View>
        <HStack style={tailwind('flex-1 mt-6')}>
          <Button
            colorScheme="primary"
            mr={2}
            py={4}
            style={tailwind('flex-1')}
            onPress={() => setFilters()}>
            Apply
          </Button>
          <Button
            onPress={() => resetFilters()}
            colorScheme="primary"
            variant="outline"
            py={4}
            style={tailwind('flex-1')}>
            Reset
          </Button>
        </HStack>
      </View>
    );
  };

  return (
    <Modal isOpen={panelActive} onClose={() => setPanelActive(false)}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Filter</Modal.Header>
        <Modal.Body>
          <KeyLogsFilterSection />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}
